import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-caution-modal',
  templateUrl: './caution-modal.page.html',
  styleUrls: ['./caution-modal.page.scss'],
})
export class CautionModalPage implements OnInit {

  @Input("message")
  message:string;

  @Input("title")
  title:string;

  constructor(private modalController:ModalController) { }

  ngOnInit() {

  }

  onClickScreen(){
    this.modalController.dismiss();
  }

}
