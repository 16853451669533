import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { Storage } from '@ionic/storage';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
  Capacitor
} from '@capacitor/core';
import { ToastService } from '../helper/toast.service';
import { AuthService } from '../auth/auth.service';

const { PushNotifications } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class FcmService {

  SERVER_ADDRESS: string;
  newPush: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(
    private storage: Storage,
    private afMessaging: AngularFireMessaging,
    private httpClient: HttpClient,
    private toastService: ToastService,
    private authService: AuthService,
  ) {
    this.SERVER_ADDRESS = environment.serverAddress;
  }

  /**
   * initialized the native push
   */
  public initNativePush() {
    PushNotifications.requestPermission().then((permission) => {
      if (permission.granted) {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // No permission for push granted
        this.toastService.showErrorToast('Registrierung für Push Benachrichtung nicht möglich.');
      }
    });

    // Registration successful
    PushNotifications.addListener(
      'registration',
      (token: PushNotificationToken) => {
        console.log('My token: ' + JSON.stringify(token));
        this.setFcmToken(token.value);
      }
    );

    // Registration not successful
    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Error: ' + JSON.stringify(error));
    });

    // Push received
    PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification: PushNotification) => {
        console.log('Push received: ' + JSON.stringify(notification));
        if (Capacitor.platform === 'android' && notification.body) {
          this.toastService.showToast(notification.body);
          console.log('update oder push to ' + notification.data.data)
          this.newPush.next(notification.data.data);
        }
      }
    );

    // push action performed
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      async (notification: PushNotificationActionPerformed) => {
        const data = notification.notification.data;
        console.log('Action performed: ' + JSON.stringify(notification.notification));
        if (data.detailsId) {
          console.log(data);
        }
      }
    );
  }


  /**
   * initialized the web push
   */
  public initWebPush() {
    console.log(this.initWebPush)
    this.requestPermission().subscribe(
      (token) => {

        console.log('My token: ' + JSON.stringify(token));
        this.setFcmToken(token)
        //this.setTokenLocalAndServer(token);
      }
    );

    this.getMessages().subscribe(
      (messaging: any) => {
        console.log(messaging);
        const NotificationOptions = {
          body: messaging.notification.body,
          data: messaging.data,
          icon: messaging.notification.icon
        }
        navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then(registration => {
          registration.showNotification(messaging.notification.title, NotificationOptions);
        });
        if (messaging.data !== undefined && messaging.data !== null
          && messaging.data.data !== undefined && messaging.data.data !== null) {
          this.setNewPush(messaging.data.data);
        }
      });
  }


  /**
   * Set the FCM token to local storage and server
   * 
   * Set token to server only works if user is logged in. 
   * Otherwise token is just saved to local storage and then send to server after successfull login. 
   * However currently this.authService.loggedIn.value not works on android properly.
   */
  /* private setTokenLocalAndServer(token: string) {
     this.setFcmToken(token).then(
       (res) => {
         if (this.authService.loggedIn.value) {
           this.setTokenServer(token).subscribe(
             (res) => {
               console.log('Set token ' + token + ' to server.')
             },
             (err) => {
               console.log('Error while setting token on server ' + token);
             }
           );
         }else{
           console.log("user is not logged in yet, keeping token in storage in setting later on server side")
         }
       }
     );
   }*/


  /**
   * requests fcm token for notifications
   */
  requestPermission() {
    return this.afMessaging.requestToken;
  }

  /**
   * get messages from firebase push
   */
  getMessages() {
    return this.afMessaging.messages;
  }

  /**
   * delete stored token
   */
  deleteFcmToken() {
    return this.storage.remove('FCM_TOKEN');
  }

  /**
   * set token for fcm
   * @param token token
   */
  setFcmToken(token: string) {
    return this.storage.set('FCM_TOKEN', token);
  }

  /**
   * get token for fcm
   * @returns Promise with token
   */
  getFcmToken() {
    return this.storage.get('FCM_TOKEN');
  }

  /**
   * Function to set token for user
   * @param token fcm token
   */
  setTokenServer(token: string): Observable<any> {
    return this.httpClient.get<any>(`${this.SERVER_ADDRESS}/rest/general/notifications/subscribe/` + token);
  }

  /**
   * set new push rcv
   */
  setNewPush(data: string) {
    this.newPush.next(data);
  }

  setFcMTokenFromStorageToServer(){
    console.log("setFcMTokenFromStorageToServer enter")
    this.getFcmToken().then(
      (token) => {
        if (token !== null) {
            this.setTokenServer(token).subscribe(
              (res) => {
                console.log('setFcMTokenFromStorageToServer Set token ' + token + ' to server.')
              },
              (err) => {
                console.log('setFcMTokenFromStorageToServe: Error while setting token to server');
              }
          ); 
        }else{
          console.log("setFcMTokenFromStorageToServer: token is null")
        }
      },
      (err) => {
        console.log("error setFcMTokenFromStorageToServer")
      }
    );
  }
}
