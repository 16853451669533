export abstract class PrintObject {

    public abstract printObject();

    public type: PrintObjectType //text

    // 0 if left, 1 if center, 2 if right
    public align: PrintObjectAlign;

    constructor(type:PrintObjectType) {
        this.type = type,
        this.align = 0;
    }

    withCenter() {
        this.align = PrintObjectAlign.CENTER;
        return this;
    }
    withLeft() {
        this.align = PrintObjectAlign.LEFT;
        return this;
    }
    withRight() {
        this.align = PrintObjectAlign.RIGHT;
        return this;
    }

}

export enum PrintObjectType {
    TEXT = 0,
    IMAGE = 1
}

export enum PrintObjectAlign {
    LEFT,
    CENTER,
    RIGHT,
}
