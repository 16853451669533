import { DatePipe } from '@angular/common';
import { Injectable, NgZone } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { VersionCheckService } from '../operations/version-check.service';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { resolve } from 'dns';
import { CautionModalPage } from 'src/app/pages/modals/caution-modal/caution-modal.page';
import { serialize } from 'v8';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {


  constructor(
    private datePipe: DatePipe,
    private versionCheckService: VersionCheckService,
    public platform: Platform,
    private appVersion: AppVersion,
    private zone: NgZone,
    private modalController: ModalController
  ) { }

  /**
   * function remove an element from an array. array will be returned.
   * element can also be an object, but element === array[i] must be true to remove it.
   * 
   * @param element element tob removed from array
   * @param array array from which the element needs to be removed
   * @returns true if removal was successful, else false
   */
  removeElementFromArray(element, array) {
    for (let i = 0; i < array.length; i++) {
      if (element === array[i]) {
        array.splice(i, 1);
        return true;
      }
    }

    return false;
  }

  /**
 * function remove an element from an array at a given index
 * 
 * @param index index to remove element at
 * @param array array from which the element needs to be removed
 * @returns true if removal was successful, else false
 */
  removeFromArrayAtIndex(array: any[], index) {
    if (array.length - 1 < index) {
      return false;
    }
    delete array[index];
    let indexNum = parseInt(index);
    for (let i = indexNum + 1; i <= array.length - 1; i++) {
      array[i - 1] = array[i]; // instead of
      // a[i] =
      // a[i+1];
    }
    array.length = array.length - 1;
    if (array.length == 0) {
      array = [];
    }
    return true;
  }


  /**
   * function to push an element to an array if it does not exist in the array.
   * @param element to push into array
   * @param array array to hold the element
   * @returns true if adding was successful, else false
   */
  addIfNotExists(element, array) {
    for (let i = 0; i < array.length; i++) {
      if (element === array[i]) {
        return false;
      }
    }

    array.push(element);
    return true;
  }

  /**
   * function to create a deep copy of an object.
   *
   * @param object object to copy
   * @returns copied instance of object
   */
  copy(object: any) {
    return JSON.parse(JSON.stringify(object));
  }

  /**
   * function to convert a parsed string to well formatted price.
   * price looks like '10.00'.
   * 
   * @param inputPrice string to convert
   * @returns formatted string of price
   */
  parseStringToPriceFormat(inputPrice: string): string {
    return parseFloat(inputPrice.toString().replace(',', '.')).toFixed(2);
  }

  /**
   * 
   * @returns an uuid
   */
  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  /**
   * gets the data url for a selected file, this data url can be displayed in an <img src="dataUrl"> tag
   * @param file
   * @returns 
   */
  getDataUrl = (file: File) => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.readAsDataURL(file)
    })
  }

  /**
   * introduces an delay ofr ms milliseconds. call this in an async function like for example await delay(300);
   * @param ms
   * @returns 
   */
  async delay(ms: number) {
    await new Promise(resolve => setTimeout(() => resolve(null), ms)).then(() => console.log("delay"));

  }

  /**
 * function to format date in human readable form
 * @param date date string to format
 * @returns string to displaygit 
 */
  formatDateString(date: string): string {
    const today = new Date();
    const dateStringDate = new Date(date);
    if (dateStringDate.getDate() === today.getDate()
      && dateStringDate.getMonth() === today.getMonth()
      && dateStringDate.getFullYear() === today.getFullYear()) {
      return 'Heute ' + this.datePipe.transform(date, 'HH:mm') + ' Uhr';
    }
    return this.datePipe.transform(date, 'dd.MM.yyyy HH:mm') + ' Uhr';
  }

  deleteFromArray(array: any[], index: string) {
    delete array[index];
    let indexNum = parseInt(index);
    for (let i = indexNum + 1; i <= array.length - 1; i++) {
      array[i - 1] = array[i]; // instead of
      // a[i] =
      // a[i+1];
    }
    array.length = array.length - 1;
    if (array.length == 0) {
      array = [];
    }
    return array;
  }

  beep() {
    //var snd = new Audio("../../../../assets/sound/mixkit-cartoon-door-melodic-bell-110.wav");
    var snd = new Audio("../../../../assets/sound/mixkit-bonus-extra-in-a-video-game-2064.wav");
    snd.play();
  }

  async getCurrentVersion() {
    if (this.isRunningOnMobilePlatform()) {
      if (this.isAndroid()) {
        return await this.appVersion.getVersionNumber();
      } else {
        return "...";
      }
    } else {
      //web version
      let curVer = this.versionCheckService.getCurrentVersion();
      return curVer == null ? "..." : curVer.substring(0, 8);
    }

  }

  isRunningOnMobilePlatform() {
    return !this.platform.is('mobileweb') && !this.platform.is('desktop');
  }

  //android web browser or native app
  isAndroid() {
    return this.platform.is('android')
  }


  async getCurrentAndroidAppInfo(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (!this.isRunningOnMobilePlatform) {
        resolve(null);
      } else {
        let versionCode = await this.appVersion.getVersionCode();
        let versionNumber = await this.appVersion.getVersionNumber();
        resolve({
          "versionCode": versionCode,
          "versionNumber": versionNumber,
        });
      }

    });

  }

  /**
   * rerenders the view (can be used in exeptional cases, e.g. push notifications)
   */
  rerenderView() {
    this.zone.run(() => {
      console.log('force update the screen');
    });
  }


  async showFeatureNotSupportedModal() {
    console.log("caution modal page")
    let currentModal = await this.modalController.create({
      component: CautionModalPage,
      cssClass: 'generic-modal-page-big',
      componentProps: {
        "title": "Hinweis",
        "message": "Diese Funktion wird auf dem Endgerät nicht unterstützt. Melde dich bei auf partner.madetogo.de über ein anderes Gerät an, um diese Funktion durchzuführen.",
      }
    });

    await currentModal.present();
  }


  /**
   * 
   * @param array with an ID property
   */
  sortById(array: any) {
    return array.sort((a, b) => a.id > b.id ? 1 : a.id === b.id ? 0 : -1);
  }

  /**
   * 
   * @returns a function for inverse exponential backoff for a given maxTimeot
   */
  getBackoffDelyFuncForBeepTimer(maxTimeout) {
    return (i, initialInterval) => {
      let delay
      if (i + 1 == 1) {
        delay = maxTimeout;
      } else if (i + 1 >= 9) {
        //constant at the end
        delay = 7000;
      } else {
        //decaying
        delay = maxTimeout / ((i + 1) * 0.6);
      }

      console.log((i + 1) + "# backoff delay.." + delay)
      return delay;
    }
  }
  mapToObj<K, V>(map: Map<K, V>): Record<string, V> {
    const obj: Record<string, V> = {};
    for (const [key, value] of map) {
      obj[String(key)] = value;
    }
    return obj;
  }
}






