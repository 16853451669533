// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


const PARTNER_DASHBOARD_ADDRESS = 'https://dev-partner.madetogo.de';
//const PARTNER_DASHBOARD_ADDRESS = 'https://localhost:8100';

//https://balramchavan.medium.com/configure-and-build-angular-application-for-different-environments-7e94a3c0af23
export const environment = {
  production: false,
  environmentName: 'DEV environment',
  firebase: {
    apiKey: "AIzaSyDxX4ld5Brd4kzhekW53LCzaY1erkDntKI",
    authDomain: "made2go.firebaseapp.com",
    databaseURL: "https://made2go.firebaseio.com",
    projectId: "made2go",
    storageBucket: "made2go.appspot.com",
    messagingSenderId: "399313823819",
    appId: "1:399313823819:web:385e425c845ba7730245ce",
    measurementId: "G-7VYT43S66N"
  },
  s3:{
    publicBucketName: "dev-s3bucket-public",
    bucketRegion: "eu-central-1"
  },
  cognito:{
    userPoolId: "eu-central-1_7gFJYBmvG",
    identityPoolId: "eu-central-1:ddb32406-7843-421c-a419-6a98ffac0bb9"
  },
  insta:{
    redirectUrl: PARTNER_DASHBOARD_ADDRESS+"/tabs/insta-redirect",
    clientId: "555481732131413"
  },
  printerApp:{
    address:'http://localhost:8101',
    //address:'http://dev-printer.madetogo.de',
  },
  partnerDashboardAddress:PARTNER_DASHBOARD_ADDRESS,
  serverAddress: 'https://dev-server.madetogo.de:8080',
  //serverAddress: 'https://dev.made2go-app.de:443'
  //serverAddress: 'http://localhost:8080',
  //serverAddress: 'https://99a787f59375.ngrok.io' //for testing printer: ionic serve --port 8100 --address 0.0.0.0 -- --disableHostCheck true, add make ngrok for server here
  combinedSwJs: 'combined-sw-dev.js',
  androidVersionUrl: "https://dev-made2go-partnerdashboard-android.s3.eu-central-1.amazonaws.com/android-version.json"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
