import { ItemOrder } from "src/app/interfaces/item/item-order";
import { PrintObject } from "../print-object";
import { TextPrintObject } from "../text-print-object";
import { OrderPrint } from "./order-print";
import { Type } from "class-transformer";
import { OrderPayment } from "../../order-payment";
import { CustomerPerk } from "src/app/interfaces/perks/customer-perk";

export class SingleOrderPrint extends OrderPrint {


    constructor() {
        super();
    }

    @Type(() => ItemOrder)
    itemOrders: ItemOrder[];
    groupOrderId: number;
    @Type(() => OrderPayment)
    orderPayment: OrderPayment;
    totalDiscount: string;

    @Type(() => CustomerPerk)
    customerPerks: CustomerPerk[];

    public printOrder(printData: PrintObject[]) {
        let customerPerksCount = this.customerPerks != null ? this.customerPerks.length : 0;
        printData.push(new TextPrintObject().withContent("Bestellposten: " + this.itemOrders.length + customerPerksCount).withBold());
        printData.push(new TextPrintObject().withContent("Bestellnummer: " + this.orderId).withBold());
        printData.push(new TextPrintObject().withEmptyLine());

        for (let itemOrder of this.itemOrders) {
            let amount = itemOrder.amount;
            // console.log(itemOrder.constructor.name)
            let itemOrderDiscription = itemOrder.getItemOrderDescription();
            printData.push(new TextPrintObject().withContent(amount + "  " + itemOrderDiscription));
            printData.push(new TextPrintObject().withContent("   " + itemOrder.totalPrice + "€ ").withRight());
            //printData.push(new TextPrintObject().withContent(".").withFullLine());
            //printData.push(new TextPrintObject().withEmptyLine());
        }
        if (this.customerPerks != null) {
            for (let customerPerk of this.customerPerks) {
                if (customerPerk.perk.perkType == 'NO_MONEY') {
                    let perkDescription = customerPerk.perk.description;
                    printData.push(new TextPrintObject().withContent(perkDescription));
                    printData.push(new TextPrintObject().withContent("Gutschein").withRight());
                }
            }
        }


        printData.push(new TextPrintObject().withContent(".").withFullLine());

        if (this.groupOrderId == 0 || this.transportType == 'pickup') {
            if (this.transportType == 'delivery') {
                printData.push(new TextPrintObject().withContent("Zwischensumme " + this.totalBasePrice + "€ ").withRight().withBold());
                printData.push(new TextPrintObject().withContent("Liefergebühr +" + this.deliveryFee + "€ ").withRight());
            }
            printData.push(new TextPrintObject().withContent("Gesamt " + this.totalPrice + "€ ").withRight().withBold());
        }

        if (this.groupOrderId != 0  && this.transportType == 'delivery') {
            printData.push(new TextPrintObject().withContent("Gesamt " + this.totalBasePrice + "€ ").withRight().withBold());
        }

        printData.push(new TextPrintObject().withEmptyLine());

        /*if (this.orderDiscount != null && parseFloat(this.totalDiscount) > 0) {
            printData.push(new TextPrintObject().withContent("Rabatt -" + this.totalDiscount + "€ ").withRight());
        }*/

        printData.push(new TextPrintObject().withContent("Online bezahlt").withBold().withL().withCenter());

        let method = this.orderPayment.getMethodName();
        printData.push(new TextPrintObject().withContent("mit " + method).withBold().withCenter());
        if (!this.orderPayment.isMethodBalance() && parseFloat(this.orderPayment.voucherPayment) > 0) {
            printData.push(new TextPrintObject().withContent("und Gutscheinzahlung").withBold().withCenter());
        }

        //printData.push(new TextPrintObject().withEmptyLine());
        printData.push(new TextPrintObject().withContent("Das ist keine Rechnung ").withCenter());
        // printData.push(new TextPrintObject().withEmptyLine());
        // printData.push(new TextPrintObject().withEmptyLine());
    }
}