import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SunmiPrinterServiceService } from 'src/app/services/printer/sunmi-printer-service.service';

@Component({
  selector: 'app-android-update-modal',
  templateUrl: './android-update-modal.page.html',
  styleUrls: ['./android-update-modal.page.scss'],
})
export class AndroidUpdateModalPage implements OnInit {

  @Input("message")
  message:string;

  @Input("title")
  title:string;

  @Input("versionName")
  versionName:string;

  constructor(private modalController:ModalController,) { }

  ngOnInit() {
  }
  onClickScreen(){
    this.onDismiss();
  }
  
  onDismiss(){

    this.modalController.dismiss();
  }

 



}
