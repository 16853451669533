import { Injectable } from '@angular/core';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';

import { Storage } from '@ionic/storage';
import { AuthResponse } from '../../interfaces/auth/auth-response';
import { RegisterData } from 'src/app/interfaces/auth/register-data';
import { RefreshTokenData } from 'src/app/interfaces/auth/refresh-token-data';
import { RefreshTokenDataResponse } from 'src/app/interfaces/auth/refresh-token-data-response';
import { environment } from 'src/environments/environment';
import { HttpStatus } from 'src/app/interfaces/auth/http-status';
import { LoginData } from 'src/app/interfaces/auth/login-data';
import { PasswordConfirm } from 'src/app/interfaces/auth/password-confirm';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  SERVER_ADDRESS: string;

  loggedIn = new BehaviorSubject(false);


  /**
   * constructor
   */
  constructor(
    private httpClient: HttpClient,
    private storage: Storage,
    ) {
      this.SERVER_ADDRESS = environment.serverAddress;
      this.updateLoggedIn();
    }

  /**
   * Function to login
   * @param user userdata
   */
  login(user: LoginData): Observable<AuthResponse> {
    user.type = 'PARTNER';
    user.username = user.email.trim();
    return this.httpClient.post<AuthResponse>(`${this.SERVER_ADDRESS}/auth/signIn`, user);
  }

  /**
   * http post to register partner
   * @param user 
   * @returns 
   */
  register(user: RegisterData): Observable<AuthResponse> {
    user.type = 'partner';
    user.username = user.email.trim();
    return this.httpClient.post<AuthResponse>(`${this.SERVER_ADDRESS}/auth/signUp/partner`, user);
  }

  /**
   * Function to logout
   */
  logout() {
    return new Promise(
      (resolve, reject) => {
        this.storage.remove('ID_TOKEN').then(
          (doneToken) => {
            this.loggedIn.next(false);
            resolve('logout');
          }
        );
      }
    );
  }

  /**
   * function to check if user is logged in
   *
   */
  isLoggedIn() {
    return this.storage.get('ID_TOKEN');
  }

  /**
   * update behaviorSubject loggedin
   */
  updateLoggedIn(): Promise<boolean> {
    return new Promise(
      (resolve, reject) => {
        this.isLoggedIn().then(
          (res) => {
            if (res !== null) {
              this.loggedIn.next(true);
            } else {
              this.loggedIn.next(false);
            }
            resolve(this.loggedIn.value);
          }
        );
      }
    );
  }

  /**
   * set all token
   */
  setAllToken(accessToken: string, refreshToken: string) {
    return new Promise(
      (resolve, reject) => {
        if (accessToken === null && refreshToken === null
          && accessToken === undefined && refreshToken === undefined)  {
          reject('tokens are undefined or null');
        }
        this.storage.set('ID_TOKEN', accessToken).then(
          () => {
            this.loggedIn.next(true);
            this.storage.set('REFRESH_TOKEN', refreshToken).then(
              () => {
                resolve('saved token');
              }
            );
          }
        );
      }
    );
  }

  /**
   * set access token
   */
  setAccessToken(accessToken: string) {
    return new Promise(
      (resolve, reject) => {
        if (accessToken === null && accessToken === undefined)  {
          reject('tokens are undefined or null');
        }
        this.storage.set('ID_TOKEN', accessToken).then(
          () => {
            this.loggedIn.next(true);
            resolve('saved token');
          }
        );
      }
    );
  }

  
  /**
   * refresh session after expired token
   */
  refreshSession() {
    return new Promise(
      (resolve, reject) => {
        this.storage.get('REFRESH_TOKEN').then(
          (refreshTokenIn) => {
            this.storage.get('ID_TOKEN').then(
              (accessTokenIn) => {
                if (refreshTokenIn !== null && accessTokenIn !== null
                  && refreshTokenIn !== undefined && accessTokenIn !== undefined) {
                  const refreshData: RefreshTokenData = {
                    refreshToken: refreshTokenIn,
                    accessToken: accessTokenIn,
                    role: 'PARTNER',
                    clientId: '6ha52jdbvu08017pnaotn252rl'
                  };
                  this.httpClient.post<RefreshTokenDataResponse>(`${this.SERVER_ADDRESS}/auth/refresh/`, refreshData).subscribe(
                    (res) => {
                      this.storage.set('ID_TOKEN', res.id_token).then(
                        () => {
                          this.loggedIn.next(true);
                          resolve(res.id_token);
                        }
                      );
                    },
                    (err) => {
                      reject('refreshing failed');
                    }
                  );
                } else {
                  reject('tokens are null or undefined');
                }
              }
            );
          }
        );
      }
    );
  }

  
  /**
   * send verify email again pls :)
   */
   resendVerifyEmail(): Observable<HttpStatus> {
    return this.httpClient.post<HttpStatus>(`${this.SERVER_ADDRESS}/rest/partner/info/resend`, {});
  }

  /**
   * function to send mail when user forgets password
   */
  passwordForgot(email): Observable<HttpStatus> {
    const data = {
      email: email.email.trim(),
      role: 'PARTNER'
    };
    return this.httpClient.post<HttpStatus>(`${this.SERVER_ADDRESS}/auth/forgotPassword`, data);
  }
  
  /**
   * confirm new password
   */
  passwordConfirm(data: PasswordConfirm): Observable<HttpStatus> {
    data.email = data.email.trim();
    return this.httpClient.post<HttpStatus>(`${this.SERVER_ADDRESS}/auth/confirmNewPassword`, data);
  }

  /**
   * get besic info of partner
   * @returns AuthResponse obj
   */
  getAuthenticatedUser():Observable<AuthResponse>{
    return this.httpClient.get<AuthResponse>(`${this.SERVER_ADDRESS}/rest/general/basicInformation`);
  }

  /**
   * convert error messages
   */
   convertAuthErrorMessages(message: string) {
    switch (message) {
      case 'USERNAME_EXISTS':
        return 'Nutzername existiert schon';
      case 'EMAIL_EXISTS':
        return 'E-Mail existiert schon';
      case 'BAD_PASSWORD':
        return 'Passwort zu schwach';
      case 'CODE_EXPIRED':
        return 'Code ist ungültig';
      case 'WRONG_USERNAME_OR_PW':
        return 'Ungültige E-Mail/Passwort!';
      case 'EMAIL_ALREADY_VERIFIED':
        return 'E-Mail schon verifiziert';
      default:
        return 'Etwas ist schief gelaufen';
    }
  }
}
