import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { CancelData } from 'src/app/interfaces/order/cancel-data';
import { OrderService } from 'src/app/services/orders/order.service';

@Component({
  selector: 'app-order-cancel-modal',
  templateUrl: './order-cancel-modal.page.html',
  styleUrls: ['./order-cancel-modal.page.scss'],
})
export class OrderCancelModalPage implements OnInit {

  currentOrderId: number;
  cancelComment = '';
  cancelReason = '';
  cancelOrderReasonText = [
    "Keinen Grund angeben",
    "Küche schon geschlossen",
    "Küche nimmt keine Bestellungen mehr auf",
    "Zutat ausgegangen",
    "Anderer Grund",
  ];

  constructor(
    private navParams: NavParams,
    private orderService: OrderService,
    private modalController: ModalController,
  ) { }

  ngOnInit() {
    this.currentOrderId = this.navParams.get('orderId');
  }

  /**
   * user clicks close
   */
  onClickClose() {
    this.modalController.dismiss();
  }

  /**
   * User clicks cancel order
   */
  onClickCancel() {
    const cancelData: CancelData = {
      cancelComment: this.cancelComment,
      cancelReason: this.cancelReason,
    }
    this.modalController.dismiss(cancelData);
  }

  /**
   * user selected cancel reason
   */
  onChangeSelect(event) {
    this.cancelReason = event.detail.value;
  }
}
