import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AddRequiredModifierModalPageRoutingModule } from './add-required-modifier-modal-routing.module';

import { AddRequiredModifierModalPage } from './add-required-modifier-modal.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AddRequiredModifierModalPageRoutingModule
  ],
  declarations: [AddRequiredModifierModalPage]
})
export class AddRequiredModifierModalPageModule {}
