import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';




const routes: Routes = [
  {
    path: '',
    redirectTo: 'tabs',
    pathMatch: 'full'
  },
  {
    path: 'tabs',
    loadChildren: () => import('./pages/tabs/tabs.module').then( m => m.TabsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'add-product-flow',
    loadChildren: () => import('./pages/slides/add-product-flow/add-product-flow.module').then( m => m.AddProductFlowPageModule)
  },
  {
    path: 'add-product-flow/:flagAllEmpty',
    loadChildren: () => import('./pages/slides/add-product-flow/add-product-flow.module').then( m => m.AddProductFlowPageModule)
  },
  {
    path: 'edit-category-item/:id',
    loadChildren: () => import('./pages/slides/edit-category-item/edit-category-item.module').then( m => m.EditCategoryItemPageModule)
  },
  {
    path: 'modifier-set-main',
    loadChildren: () => import('./pages/slides/modifier-set-main/modifier-set-main.module').then( m => m.ModifierSetMainPageModule)
  },
  {
    path: 'add-image',
    loadChildren: () => import('./pages/modals/add-image/add-image.module').then( m => m.AddImagePageModule)
  },
  {
    path: 'edit-image-popover',
    loadChildren: () => import('./pages/popovers/edit-image-popover/edit-image-popover.module').then( m => m.EditImagePopoverPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/auth/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'password-forget',
    loadChildren: () => import('./pages/auth/password-forget/password-forget.module').then( m => m.PasswordForgetPageModule)
  },
  { 
    path: 'verify-email',
    loadChildren: () =>
      import('./pages/auth/verify-email/verify-email.module').then(
        m => m.VerifyEmailPageModule
      )
  },
  { 
    path: 'password-forget-confirm/:email',
    loadChildren: () =>
      import('./pages/auth/password-forget-confirm/password-forget-confirm.module').then(
        m => m.PasswordForgetConfirmPageModule
      )
  },
  {
    path: 'edit-orders-config-popover',
    loadChildren: () => import('./pages/popovers/edit-orders-config-popover/edit-orders-config-popover.module').then( m => m.EditOrdersConfigPopoverPageModule)
  },
  {
    path: 'edit-paypal-page',
    loadChildren: () => import('./pages/modals/edit-paypal-page/edit-paypal-page.module').then( m => m.EditPaypalPagePageModule)
  },
  {
    path: 'edit-paypal-popover',
    loadChildren: () => import('./pages/popovers/edit-paypal-popover/edit-paypal-popover.module').then( m => m.EditPaypalPopoverPageModule)
  },
  {
    path: 'edit-order-settings',
    loadChildren: () => import('./pages/modals/edit-order-settings/edit-order-settings.module').then( m => m.EditOrderSettingsPageModule)
  },
  {
    path: 'edit-delivery-settings',
    loadChildren: () => import('./pages/modals/edit-delivery-settings/edit-delivery-settings.module').then( m => m.EditDeliverySettingsPageModule)
  },
  {
    path: 'edit-delivery-settings-popover',
    loadChildren: () => import('./pages/popovers/edit-delivery-settings-popover/edit-delivery-settings-popover.module').then( m => m.EditDeliverySettingsPopoverPageModule)
  },
  {
    path: 'add-notification-email',
    loadChildren: () => import('./pages/modals/add-notification-email/add-notification-email.module').then( m => m.AddNotificationEmailPageModule)
  },
  {
    path: 'show-order-qr-code',
    loadChildren: () => import('./pages/modals/show-order-qr-code/show-order-qr-code.module').then( m => m.ShowOrderQrCodePageModule)
  },
  {
    path: 'edit-printer-settings-popover',
    loadChildren: () => import('./pages/popovers/edit-printer-settings-popover/edit-printer-settings-popover.module').then( m => m.EditPrinterSettingsPopoverPageModule)
  },
  {
    path: 'audio-confirm',
    loadChildren: () => import('./pages/modals/audio-confirm/audio-confirm.module').then( m => m.AudioConfirmPageModule)
  },
  {
    path: 'android-update-modal',
    loadChildren: () => import('./pages/modals/android-update-modal/android-update-modal.module').then( m => m.AndroidUpdateModalPageModule)
  },
  {
    path: 'caution-modal',
    loadChildren: () => import('./pages/modals/caution-modal/caution-modal.module').then( m => m.CautionModalPageModule)
  },  {
    path: 'edit-allergenes-modal',
    loadChildren: () => import('./pages/modals/edit-allergenes-modal/edit-allergenes-modal.module').then( m => m.EditAllergenesModalPageModule)
  },
  {
    path: 'edit-additives-modal',
    loadChildren: () => import('./pages/modals/edit-additives-modal/edit-additives-modal.module').then( m => m.EditAdditivesModalPageModule)
  },
  {
    path: 'edit-modifier-item',
    loadChildren: () => import('./pages/modals/edit-modifier-item/edit-modifier-item.module').then( m => m.EditModifierItemPageModule)
  },










];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules,  }),//useHash: true
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
