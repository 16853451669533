import { Component, OnInit } from '@angular/core';
import { Insomnia } from '@ionic-native/insomnia/ngx';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import firebase from 'firebase';
import { FcmService } from './services/firebase/fcm.service';
import { AuthService } from './services/auth/auth.service';
import { VersionCheckService } from './services/operations/version-check.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import NoSleep from 'nosleep.js';
import { UtilityService } from './services/helper/utility.service';
import { AndroidVersionCheckService } from './services/operations/android-version-check.service';

//variable that indicates if page was refreshed
export let browserRefresh = false;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  // firebaseConfig = {
  //   apiKey: "AIzaSyDxX4ld5Brd4kzhekW53LCzaY1erkDntKI",
  //   authDomain: "made2go.firebaseapp.com",
  //   databaseURL: "https://made2go.firebaseio.com",
  //   projectId: "made2go",
  //   storageBucket: "made2go.appspot.com",
  //   messagingSenderId: "399313823819",
  //   appId: "1:399313823819:web:385e425c845ba7730245ce",
  //   measurementId: "G-7VYT43S66N"
  // };

  // vapidKey = 'BGToNiHnV_g23Ipa-GuuZ3tX46Vdgx2iIeDCtn9jknQxgzUNklL9Du7vQWMi2-xnM5MZVo7zW115zjLM47wN7UI';

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private fcmService: FcmService,

    private versionCheckService: VersionCheckService,
    private utilityService: UtilityService,
    private route: ActivatedRoute,
    private router: Router,
    private androidVersionCheckService: AndroidVersionCheckService
  ) {
    this.initializeApp();
    
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
      }
    });

  }
  ngOnInit(): void {
    if (!this.utilityService.isRunningOnMobilePlatform()) {
      console.log("listening for version updates");
      this.versionCheckService.initVersionCheck('./version.json', 300000);
      // this.androidVersionCheckService.initVersionCheck(environment.androidVersionUrl,20000)
    } else {
      console.log("runnung on ios or android, no version check service needed")
      this.androidVersionCheckService.initVersionCheck(environment.androidVersionUrl, 300000)
      //set token to server every time user opens, app in addition to after login, may fail with 401 if user not logged in
      this.fcmService.setFcMTokenFromStorageToServer(); //TODO: evtl in ionViewWillEnter auslagern (update: probiert, aber ionViewWillEnter lässt sich nicht triggern)
    }
  }



  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      if (this.utilityService.isRunningOnMobilePlatform()) {
        //native
        this.fcmService.initNativePush();
      } else {
        this.fcmService.initWebPush();
      }
    });
  }


}
