import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AddItemInstaceImagePageRoutingModule } from './add-item-instace-image-routing.module';

import { AddItemInstaceImagePage } from './add-item-instace-image.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AddItemInstaceImagePageRoutingModule
  ],
  declarations: [AddItemInstaceImagePage]
})
export class AddItemInstaceImagePageModule {}
