import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonSlides, ModalController, NavParams } from '@ionic/angular';
import { CategoryItem } from 'src/app/interfaces/item/category-item';
import { Modifier } from 'src/app/interfaces/item/modifier';
import { ModifierSet } from 'src/app/interfaces/item/modifier-set';
import { LoadingService } from 'src/app/services/helper/loading.service';
import { ToastService } from 'src/app/services/helper/toast.service';
import { UtilityService } from 'src/app/services/helper/utility.service';
import { ProductService } from 'src/app/services/product-management/product.service';
import { AddModifierModalPage } from '../add-modifier-modal/add-modifier-modal.page';
import { AddRequiredModifierModalPage } from '../add-required-modifier-modal/add-required-modifier-modal.page';

@Component({
  selector: 'app-add-new-modifier-set-to-product',
  templateUrl: './add-new-modifier-set-to-product.page.html',
  styleUrls: ['./add-new-modifier-set-to-product.page.scss'],
})
export class AddNewModifierSetToProductPage implements OnInit {

  @ViewChild('slidesModal', { static: false }) slider: IonSlides;
  swiper: any;
  
  addWhat = '';
  buttonText = 'Weiter';
  slideOpts = {
    initialSlide: 0,
    speed: 400,
    allowTouchMove: false
  };
  
  modifierSet: ModifierSet = {
    availability: 'AVAILABLE',
    internalName: '',
    modifierSetId: {
      modifierSetId: 0
    },
    modifierSetRule: 'optional',
    modifiers: [],
    name: '',
    selectAmount: 100,
    selectAmountRule: 'max'
  };
  requiredModifierSet: ModifierSet = {
    availability: 'AVAILABLE',
    internalName: '',
    modifierSetId: {
      modifierSetId: 0
    },
    modifierSetRule: 'required',
    modifiers: [],
    name: '',
    selectAmount: 100,
    selectAmountRule: 'max'
  };

  product: CategoryItem;

  emptyModifierSet = false;
  emptyModifiers = false;
  emptyRequiredModifierSet = false;

  constructor(
    private productService: ProductService,
    private loadingService: LoadingService,
    private toastService: ToastService,
    private utilityService: UtilityService,
    private modalController: ModalController,
    private navParams: NavParams,
    ) { }

  ngOnInit() {
    this.product = this.navParams.get('product');
  }

  ionViewDidEnter() {
    this.slider.getSwiper().then(
      (swiperInstance) => {
        this.swiper = swiperInstance;
      }
    );
  }
  
  /**
   * user clicks product or category
   * @param event ion change
   */
   onRadioChanged(event) {
    this.addWhat = event.detail.value;
  }

  /**
   * user click continue
   */
  onClickContinue() {
    switch (this.swiper.activeIndex) {
      case 0: // init slide choose product or category
          this.swiper.slideNext();
          if (this.addWhat === 'modifiers') {
            this.buttonText = 'Bestätigen';
          }

        break;
      case 1: // category or product slide
        if (this.modifierSet.name === '' && this.addWhat === 'modifiers') {
          this.emptyModifierSet = true;
          break;
        } else if (this.modifierSet.modifiers.length === 0 && this.addWhat === 'modifiers') {
          this.emptyModifiers = true;
          break;
        }
        if (this.addWhat === 'modifiers') {
          this.emptyModifiers = false;
          this.emptyModifierSet = false;;
          this.modifierSet.internalName = this.modifierSet.name;
          this.productService.postPartnerModifierSet(this.modifierSet).subscribe(
            (res) => {
              this.loadingService.stopLoading();          
              this.toastService.showToast('Auswahlmöglichkeit hinzugefügt.');
              this.modifierSet.modifierSetId = res.modifierSetId;
              this.product.modifierSets.push(this.modifierSet);
              this.modalController.dismiss();
            },
            (err) => {
              this.loadingService.stopLoading();
              this.toastService.showErrorToast('Auswahlmöglichkeit hinzufügen nicht möglich.');
            }
          );
        }

        if (this.requiredModifierSet.name === '' && this.addWhat === 'requiredModifiers') {
          this.emptyRequiredModifierSet = true;
          break;
        } else if (this.requiredModifierSet.modifiers.length === 0 && this.addWhat === 'requiredModifiers') {
          this.emptyModifiers = true;
          break;
        }
        if (this.addWhat === 'requiredModifiers') {
          this.emptyModifiers = false;
          this.emptyRequiredModifierSet = false;

          this.loadingService.startLoading('');
          this.requiredModifierSet.internalName = this.requiredModifierSet.name;
          this.productService.postPartnerModifierSet(this.requiredModifierSet).subscribe(
            (res) => {
              this.loadingService.stopLoading();         
              this.toastService.showToast('Auswahlmöglichkeit hinzugefügt.');
              this.requiredModifierSet.modifierSetId = res.modifierSetId;
              this.product.modifierSets.push(this.requiredModifierSet);
              this.modalController.dismiss();
            },
            (err) => {
              this.loadingService.stopLoading();
              this.toastService.showErrorToast('Auswahlmöglichkeit hinzufügen nicht möglich.');
            }
          );
        }

        break;
    }
  }

  /**
   * user clicks back
   */
  onClickBack() {
    this.swiper.slidePrev();
  }

    /**
   * user wants to add modifiers to modifierSet
   */
    async onClickAddModifier() {
    const modal = await this.modalController.create({
      component: AddModifierModalPage,
      cssClass: 'generic-modal-page-big',
    });

    modal.present();

    modal.onDidDismiss().then(
      (res) => {
        if (res.data !== undefined) {
          this.modifierSet.modifiers.push(res.data);
        }
      }
    );
  }

  /**
   * user deletes modifier in selection
   */
  onClickDeleteModifier(modifier: Modifier) {
    this.utilityService.removeElementFromArray(modifier, this.modifierSet.modifiers);
  }

  /**
   * user deletes modifier in selection
   */
  onClickDeleteRequiredModifier(modifier: Modifier) {
    this.utilityService.removeElementFromArray(modifier, this.modifierSet.modifiers);
  }

  /**
   * user wants to add modifiers to modifierSet
   */
  async onClickAddRequiredModifier() {
    const modal = await this.modalController.create({
      component: AddRequiredModifierModalPage,
      cssClass: 'generic-modal-page-big',
    });

    modal.present();

    modal.onDidDismiss().then(
      (res) => {
        if (res.data !== undefined) {
          this.requiredModifierSet.modifiers.push(res.data);
          console.log(this.requiredModifierSet)
        }
      }
    );
  }
}
