import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    private toastController: ToastController,
  ) { }

  /**
   * function to show toast
   * 
   * @param message string to show
   */
  async showToast(messageIn: string) {
    const toast = await this.toastController.create({
      message: messageIn,
      duration: 5000,
      color:"success"
    });
    toast.present();
  }

   /**
   * function to show toast
   * 
   * @param message string to show
   */
  async showErrorToast(messageIn: string) {
    const toast = await this.toastController.create({
      message: messageIn,
      duration: 3000,
      color:"danger"
    });
    toast.present();
  }
}
