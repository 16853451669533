import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private loadingCounter = 0;

  constructor(private loadingController: LoadingController) { }

  /**
   * start loading
   */
  async startLoading(message: string) {
    this.loadingCounter++;
    if (message === undefined) {
      message = '';
    }
    await this.loadingController.getTop().then(
      (isLoadingTop) => {
        if (!isLoadingTop) {
          this.loadingController
            .create({
              spinner: 'crescent',
              translucent: true,
              message,
            })
            .then(
              (loading) => {
                loading.present().then(
                  () => {
                    if (this.loadingCounter <= 0) {
                      loading.dismiss();
                    }
                  }
                );
              }
            );
        }
      }
    );
  }

  /**
   * stop loading
   */
  stopLoading() {
    this.loadingCounter--;
    if (this.loadingCounter <= 0) {
      this.loadingCounter = 0;
    }
    this.loadingController.getTop().then(
      (isLoading) => {
        if (this.loadingCounter <= 0) {
          this.loadingController.dismiss();
        }
      },
    );
  }


}