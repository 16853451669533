import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CategoryItem } from 'src/app/interfaces/item/category-item';
import { InitialItemCreationInfo } from 'src/app/interfaces/item/initial-item-creation-info';
import { ModifierItem } from 'src/app/interfaces/item/modifier-item';
import { ModifierSet } from 'src/app/interfaces/item/modifier-set';
import { PartnerCategories } from 'src/app/interfaces/item/partner-categories';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  SERVER_ADDRESS: string;
  // reload products when flag is switch from 0 to 1 or 1 to 0
  reloadProductsSwitchFlag: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /**
   * constructor
   */
  constructor(
    private httpClient: HttpClient
    ) {
      this.SERVER_ADDRESS = environment.serverAddress;
  }

  /**
   * 
   * GET CALLS
   * 
   */

  /**
   * Function to get partner categories with items
   */
  getPartnerCategoriesWithItems(): Observable<PartnerCategories[]> {
    return this.httpClient.get<PartnerCategories[]>(`${this.SERVER_ADDRESS}/rest/partner/item/categories-with-items`);
  }

  /**
   * Function to get partner categories
   */
  getPartnerCategories(): Observable<PartnerCategories[]> {
    return this.httpClient.get<PartnerCategories[]>(`${this.SERVER_ADDRESS}/rest/partner/item/categories`);
  }
  
  /**
   * Function to get specific item
   * 
   * @param id id of item
   */
  getPartnerItem(id: number): Observable<CategoryItem> {
    return this.httpClient.get<CategoryItem>(`${this.SERVER_ADDRESS}/rest/partner/item/` + id);
  }

  /**
   * Function to update item or create
   */
  getPartnerModifierSet(): Observable<ModifierSet[]> {
    return this.httpClient.get<ModifierSet[]>(`${this.SERVER_ADDRESS}/rest/partner/modifierSet/`);
  }

  /**
   * 
   * POST CALLS
   * 
   */


  /**
   * Function to create new partner category
   * 
   * @param name name of new category
   */
  postPartnerCategory(name: string): Observable<PartnerCategories> {
    const cat = {
      description: '',
      name: name,
    };
    return this.httpClient.post<PartnerCategories>(`${this.SERVER_ADDRESS}/rest/partner/item/category`, cat);
  }

  /**
   * Function to update new partner category
   * 
   * @param category category
   */
  postPartnerCategoryWithItems(category): Observable<PartnerCategories[]> {
    return this.httpClient.post<PartnerCategories[]>(`${this.SERVER_ADDRESS}/rest/partner/item/categories-with-items`, category);
  }

  /**
   * Function to update modifierSET or create
   * 
   * @param modifierSet modifierSet
   */
  postPartnerModifierSet(modifierSet: ModifierSet): Observable<ModifierSet> {
    return this.httpClient.post<ModifierSet>(`${this.SERVER_ADDRESS}/rest/partner/modifierSet/`, modifierSet);
  }

  /**
   * Function to update modifierItem or create
   * 
   * @param modifierItem modifierItem
   */
  postPartnerModifierItem(modifierItem: ModifierItem): Observable<ModifierItem> {
    return this.httpClient.post<ModifierItem>(`${this.SERVER_ADDRESS}/rest/partner/modifierItem/`, modifierItem);
  }

  /**
   * Function to update item or create
   */
  postPartnerItem(item): Observable<CategoryItem> {
    return this.httpClient.post<CategoryItem>(`${this.SERVER_ADDRESS}/rest/partner/item/`, item);
  }

  getPartnerInitalItemCreation(): Observable<InitialItemCreationInfo>{
    return this.httpClient.get<InitialItemCreationInfo>(`${this.SERVER_ADDRESS}/rest/partner/items/initial`);
  }

  postVerifyAllItemsForPartner(): Observable<void>{
    return this.httpClient.post<void>(`${this.SERVER_ADDRESS}/rest/partner/items/verify`, null);
  }


  /**
   * 
   * DELETE CALLS
   * 
   */

  /**
   * Function to delete partner category
   * 
   * @param id id of category
   */
  deletePartnerCategory(id: number): Observable<string> {
    return this.httpClient.delete<string>(`${this.SERVER_ADDRESS}/rest/partner/item/category/` + id);
  }

  /**
   * Function to delete partner item
   * 
   * @param id id of item
   */
  deletePartnerProduct(id: number): Observable<string> {
    return this.httpClient.delete<string>(`${this.SERVER_ADDRESS}/rest/partner/item/` + id);
  }

  /**
   * Function to delete modifier Set
   * 
   * @param id id of modifierSet
   */
  deleteModifierSet(id: number): Observable<string> {
    return this.httpClient.delete<string>(`${this.SERVER_ADDRESS}/rest/partner/modifierSet/` + id);
  }
  

  
  /**
   * set new product reload
   */
  setReloadProduct() {
    this.reloadProductsSwitchFlag.next(!this.reloadProductsSwitchFlag.value);
  }


  getItemInstancesSorter(){
    return (a, b) =>  parseFloat(a.price) - parseFloat(b.price);
  }
}
