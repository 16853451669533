import { Type } from "class-transformer";
import { PrintObject } from "../print-object";
import { TextPrintObject } from "../text-print-object";
import { OrderPrint } from "./order-print";
import { SingleOrderPrint } from "./single-order-print";

export class GroupOrderPrint extends OrderPrint {

  constructor() {
    super();
  }


  @Type(() => SingleOrderPrint)
  orders: SingleOrderPrint[];



  public printOrder(printData: PrintObject[]) {

    // printData.push(new TextPrintObject().withContent("Gruppenbestellung  " + joinedCustomersCount + " Personen"));
    //printData.push(new TextPrintObject().withEmptyLine());

    let index = 1;
    for (let singleOrder of this.orders) {
      printData.push(new TextPrintObject().withContent("#" + this.orderId + " (" + index + "/" + this.orders.length + ")").withBold().withL());
      printData.push(new TextPrintObject().withContent(singleOrder.orderer.surname + " " + singleOrder.orderer.lastname).withBold());
      (<SingleOrderPrint>singleOrder).printOrder(printData);
      printData.push(new TextPrintObject().withEmptyLine());
      printData.push(new TextPrintObject().withEmptyLine());
     // printData.push(new TextPrintObject().withEmptyLine());
      printData.push(new TextPrintObject().withContent("*").withFullLine());
      if(index<this.orders.length){
        printData.push(new TextPrintObject().withEmptyLine());
        printData.push(new TextPrintObject().withEmptyLine());
       // printData.push(new TextPrintObject().withEmptyLine());
      }
    
      index++;
    }

    if (this.transportType == 'pickup') {
      printData.push(new TextPrintObject().withContent("Gesamt: "+this.totalPrice+" €").withBold().withL().withRight());
    }else{
      printData.push(new TextPrintObject().withContent("Zwischensumme " + this.totalBasePrice + "€ ").withRight().withBold());
      printData.push(new TextPrintObject().withContent("Liefergebühr +" + this.deliveryFee + "€ ").withRight());
      printData.push(new TextPrintObject().withContent("Gesamt: "+this.totalPrice+" €").withBold().withL().withRight());
    }

   
  }

}