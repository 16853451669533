import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { PartnerCategory } from 'src/app/interfaces/settings/partner-category';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { Address } from 'src/app/interfaces/settings/address';


@Component({
  selector: 'app-edit-basicinfo',
  templateUrl: './edit-basicinfo.page.html',
  styleUrls: ['./edit-basicinfo.page.scss'],
})
export class EditBasicinfoPage implements OnInit,  AfterViewInit {

  @Input() officialName: string;
  @Input() username: string;
  @Input() phone: string;
  @Input() websiteUrl: string;
  @Input() contactEmail: string;
  @Input() businessCategory:string;
  @Input() partnerCategories:PartnerCategory[];


  allPartnerCategories:PartnerCategory[];



  constructor(private modalController: ModalController, private settingsService:SettingsService) { }
  
  ngAfterViewInit(): void {
    this.getPartnerCategories();
  }

  ngOnInit() {
    console.log(this.officialName)
  
  }

  

  onDismiss(){
    this.modalController.dismiss();
  }

  onSave(){
    this.modalController.dismiss({
      "officialName":this.officialName,
      "username":this.username,
      "phone":this.phone,
      "websiteUrl":this.websiteUrl,
      "contactEmail":this.contactEmail,
      "partnerCategories":this.partnerCategories,
     
    });
  }

  getPartnerCategories(){
    this.settingsService.getPartnerCategoriesByBusinessCategory(this.businessCategory).subscribe(res=>{
      this.allPartnerCategories = res;
    })
  }

  deletePartnerCategory(i){
  this.deleteFromArray(this.partnerCategories, i)
  }

  addPartnerCategory(){
    this.partnerCategories.push({
      "id":0,
      "name":""
    })
    }

  deleteFromArray(array:any[], index:string) {
    delete array[index];
    let indexNum = parseInt(index);
    for (let i = indexNum + 1; i <= array.length - 1; i++) {
      array[i - 1] = array[i]; // instead of
      // a[i] =
      // a[i+1];
    }
    array.length = array.length - 1;
    if (array.length == 0) {
      array = [];
    }
    return array;
  }
}
