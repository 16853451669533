import { ItemInstance } from "./item-instance";
import { ModifierSetOrder } from "./modifier-set-order";
import { Type } from "class-transformer";

export class ItemOrder {
    additionalCosts: string;
    amount: number;
    @Type(() => ItemInstance)
    itemInstance: ItemInstance;
    modifierSetOrders: ModifierSetOrder[];
    subTotal: string;
    totalPrice: string;
    type: string;
    customerComment:string;


    public getItemOrderDescription?() {
        let res = this.itemInstance.getItemInstanceName();
        if (this.type == "product") {
            for (let modifierSetOrder of this.modifierSetOrders) {
                let modifierSetName = modifierSetOrder.modifierSet.name;
                let modifiers = modifierSetOrder.modifiers;
                if (modifiers.length > 0) {
                    res += "\n"
                    res += modifierSetName + ": \n";
                }
                let count = 0;
                for (let modifier of modifiers) {
                    res += modifier.modifierItem.name;

                    res += (parseFloat(modifier.price) == 0 ? ""
                        : " (+" + modifier.price + "€)");
                    if (count < modifiers.length - 1) {
                        res += ", \n";
                    }
                    count++;
                }

            }
        }
        if(this.customerComment!=null){
            res +="\n"
            res +="Anmerkung:"+"'"+this.customerComment+"'";
        }
        return res;
    }
}
