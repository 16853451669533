import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AndroidUpdateModalPage } from 'src/app/pages/modals/android-update-modal/android-update-modal.page';
import { InterceptorSkipHeader } from '../auth/auth-interceptor.service';
import { LoadingService } from '../helper/loading.service';
import { ToastService } from '../helper/toast.service';
import { UtilityService } from '../helper/utility.service';
import { SunmiPrinterServiceService } from '../printer/sunmi-printer-service.service';

/**
 * Version check service for SUNMI v2 device
 */
@Injectable({
  providedIn: 'root'
})
export class AndroidVersionCheckService {

  currentModal:any = null;

  constructor(private http: HttpClient,
      private modalController:ModalController,
      private sunmiPrinterService: SunmiPrinterServiceService,
      private loadingService: LoadingService,
      private  utilityService:UtilityService,
      private toastService: ToastService,) { }

  /**
   * Checks in every set frequency the version of frontend application
   * @param url
   * @param {number} frequency - in milliseconds, defaults to 30 minutes
   */
  public initVersionCheck(url, frequency = 1000 * 60 * 30) {
    //do the version check right at beginning
    this.checkVersion(url);

    setInterval(() => {
      this.checkVersion(url);
    }, frequency);
  }

  /**
    * Will do the call and check if the hash has changed or not
    * @param url
    */
  private checkVersion(url) {
    console.log("checking for new version..");
    // timestamp these requests to invalidate caches
    const headers = new HttpHeaders().set(InterceptorSkipHeader, '');
    this.http.get(url + '?t=' + new Date().getTime(),{headers})
      //.first()
      .subscribe(
        async (response: any) => {
          let versionCode = response.versionCode;
          let versionName = response.versionName;

          let appInfo = await this.utilityService.getCurrentAndroidAppInfo();
          //console.log(appInfo) does not work
          console.log(appInfo.versionCode)
          console.log(versionCode)

          if(appInfo.versionCode>=versionCode){
            console.log("current version code is "+appInfo.versionCode+", new version code is "+versionCode +", no update needed");
            return;
          }

          if(this.currentModal != null){
            console.log("modal already opened")
            return;
          }
          this.currentModal = await this.modalController.create({
            component: AndroidUpdateModalPage,
            cssClass: 'generic-modal-page-full-width',
            componentProps: {
              "message":response.message,
              "title":response.title,
              "versionName":versionName
            }
          });
      
          this.currentModal.present();
      
          this.currentModal.onWillDismiss().then(async data => {
            console.log("update modal was dismissed")
            this.loadingService.startLoading("Weiterleitung zum App Store...\n Update startet automatisch");
            try{
              this.sunmiPrinterService.goToAppStore();
            }catch(error){
              this.toastService.showErrorToast("Fehler beim Update. Schließe bitte die App und gehe zum App Store. Das Update startet dann automatisch.")
            }finally{
              this.currentModal = null;
              await this.utilityService.delay(10000);
              this.loadingService.stopLoading();
            }
          });

        },
        (err) => {
          console.error(err, 'Could not get version');
        }
      );

  }

}
