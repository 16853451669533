import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    public authService: AuthService,
    private router: Router,
    ) {}

  canActivate(): Promise<boolean> {
    return this.authService.updateLoggedIn().then(
      (res) => {
        if (!res) {
          this.router.navigate(['login']);
        }
        return res;
      }
    );
  }
}
