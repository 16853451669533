import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Order } from 'src/app/interfaces/order/order';
import { GroupOrderPrint } from 'src/app/interfaces/order/print/order/group-order-print';
import { OrderPrint } from 'src/app/interfaces/order/print/order/order-print';
import { SingleOrderPrint } from 'src/app/interfaces/order/print/order/single-order-print';
import { PrintObject } from 'src/app/interfaces/order/print/print-object';
import { TextPrintObject } from 'src/app/interfaces/order/print/text-print-object';
import { VersionCheckService } from '../operations/version-check.service';
import { plainToClass } from "class-transformer";
import { ImagePrintObject } from 'src/app/interfaces/order/print/image-print-object';
import { UtilityService } from '../helper/utility.service';

declare var sunmiInnerPrinter: any;

@Injectable({
  providedIn: 'root'
})
export class SunmiPrinterServiceService {
  goToAppStore() {
    sunmiInnerPrinter.goToAppStore();
  }

  constructor( 
    private versionCheckService: VersionCheckService,
    private utilityService:UtilityService) { }

  private mapOrderToOrderOrderPrint(orderToMap:Order):OrderPrint{
    let order: OrderPrint
    if (orderToMap.type == "single") {
      order = plainToClass(SingleOrderPrint, orderToMap);
    } else {
      order = plainToClass(GroupOrderPrint, orderToMap);
    }
    return order;
  }

  /*private getMade2GoLogoBase64():string{
    return "xyz;" //https://github.com/labibramadhan/cordova-sunmi-inner-printer/issues/4
  }*/

  printOrderData(orderToMap: Order) {

    let order: OrderPrint = this.mapOrderToOrderOrderPrint(orderToMap);

    let printData: PrintObject[] = [];

    let transportTypeString = order.transportType == "pickup" ? "Abholung" : "Lieferung";
    let orderType = order.type == "single" ? "Einzel" : "Gruppen";
    printData.push(new TextPrintObject().withContent("Made2Go").withBold().withM().withCenter());
    //printData.push(new ImagePrintObject(this.getMade2GoLogoBase64(),182,161));
    printData.push(new TextPrintObject().withContent(orderType + "-" + transportTypeString).withBold().withM().withCenter());
    printData.push(new TextPrintObject().withContent("#" + order.orderId).withBold().withM().withCenter());
    printData.push(new TextPrintObject().withEmptyLine());
    printData.push(new TextPrintObject().withContent("um " + moment(order.timeAcceptance).format("HH:mm") + " Uhr").withBold().withM().withCenter());
    //if(moment().format("DD.MM.YYYY") != moment(order.timeAcceptance).format("DD.MM.YYYY")){
    printData.push(new TextPrintObject().withContent("am " + moment(order.timeAcceptance).format("DD.MM.YYYY")).withCenter());
    //}

    printData.push(new TextPrintObject().withContent(".").withFullLine());
    if (order.transportType == "pickup") {
      printData.push(new TextPrintObject().withContent("Abholer: \n" + order.pickuper.surname + " " + order.pickuper.lastname));
      if (order.pickuper.mobilePhoneNumber != null) {
        printData.push(new TextPrintObject().withContent("Telefon: " + order.pickuper.mobilePhoneNumber));
      }
    } else {
      printData.push(new TextPrintObject().withContent("Besteller: \n" + order.orderer.surname + " " + order.orderer.lastname));
      if (order.orderer.mobilePhoneNumber != null) {
        printData.push(new TextPrintObject().withContent("Telefon: " + order.orderer.mobilePhoneNumber));
      }
      printData.push(new TextPrintObject().withContent("Lieferadresse:"));
      printData.push(new TextPrintObject().withContent(order.deliveryAddress.addressLine1));
      printData.push(new TextPrintObject().withContent(order.deliveryAddress.addressLine2));
      printData.push(new TextPrintObject().withContent(order.deliveryAddress.zipCode + " " + order.deliveryAddress.city));
    }

    printData.push(new TextPrintObject().withContent(".").withFullLine());
    //printData.push(new TextPrintObject().withContent("v." + this.getCurrentVersion()).withRight());

    order.printOrder(printData);

    printData.push(new TextPrintObject().withEmptyLine());
    printData.push(new TextPrintObject().withEmptyLine());
    printData.push(new TextPrintObject().withEmptyLine());
    this.printData(printData);
  }

  private printData(printData: PrintObject[]) {

    for (let printObject of printData) {
      printObject.printObject();
    }
  }

}
