export class OrderPayment{
    constructor() {
    }

    balancePay: string;
	perkPay: string;
	voucherPayment: string;
    totalPriceToPay: string;
    source: string;
	

    public getMethodName(){
        if (this.isMethodPaypal()) {
			return "PayPal";
		} else if (this.isMethodCard()) {
			return "Kartenzahlung";
		} else
			return "Gutscheinzahlung";

    }

    public  isMethodPaypal() {
		if (this.source == "paypal") {
			return true;
		} else {
			return false;
		}
	}

	public  isMethodCard() {
		if (this.source == "stripe") {
			return true;
		} else {
			return false;
		}
	}

	public  isMethodBalance() {
		if (this.source == "balance") {
			return true;
		} else {
			return false;
		}
	}
}