import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AddModifierModalPageRoutingModule } from './add-modifier-modal-routing.module';

import { AddModifierModalPage } from './add-modifier-modal.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AddModifierModalPageRoutingModule
  ],
  declarations: [AddModifierModalPage]
})
export class AddModifierModalPageModule {}
