import { ItemInstanceImage } from "../settings/item-instance-image";
import { ItemDiscount } from "./item-discount";
import { ModifierSet } from "./modifier-set";

export class ItemInstance {
    deposit: string;
    discountedBasePrice?: string;
    item?: {
        name: string;
    };
    itemDiscount?: ItemDiscount;
    price: string;
    subTotal?: string;
    productType?: string;
    name: string;
    type: string;
    availability: string;
    images?:ItemInstanceImage[];
    itemInstanceCarteId?:string;
    modifierSets?:ModifierSet[];

    public getItemInstanceName?() {
        let res = this.item.name;
        if (this.type == "product") {
            if (this.name!=null && this.name != "$default!") {
                res += " (" + this.name + ")";
            }
            //TODO erst einführen, wenn man Tabelle macht
            /*res+=" ("+this.price+"€";

            if (parseFloat(this.deposit) != 0) {
                res += " inkl. " + this.deposit + "€ Pfand";
            }
            res+=")"*/
        }

        if(this.itemInstanceCarteId!=null){
            res+="(#"+this.itemInstanceCarteId+")";
        }
        return res;
    }
}
