import { PrintObject, PrintObjectAlign, PrintObjectType } from "./print-object";

declare var sunmiInnerPrinter: any;

export class TextPrintObject extends PrintObject {

     private NORMAL_SIZE = 31;
     private MAXCHARS_NORMAL_SIZE = 25;
     //TODO getMaxChars based on size
     private M_SIZE = 35;
     private L_SIZE = 40;
     private XL_SIZE = 50;
     private XXL_SIZE = 60;
    
  
     public format: TextPrintObjectFormat = TextPrintObjectFormat.NORMAL;
     public content: string = "";
     public bold: boolean = false;
     public fullLine: boolean = false;
     public emptyLine: boolean =false;
 
     constructor() {
         super(PrintObjectType.TEXT)
     }

    printObject() {
        console.log("printer init");
        sunmiInnerPrinter.printerInit();
        console.log("printer print");
        sunmiInnerPrinter.setAlignment(this.align);
        sunmiInnerPrinter.setFontSize(this.getFontSize());

        if (this.fullLine) {
            let charlen = this.content.length;
            let maxChar = this.MAXCHARS_NORMAL_SIZE;
            let newContent = "";
            for (let i = 1; i < maxChar; i += charlen) {
                newContent += this.content;
            }
            this.content = newContent;
        }

        if(this.content.trim() == ""|| this.emptyLine){
            sunmiInnerPrinter.printOriginalText(" \n");
        }else{
            sunmiInnerPrinter.printOriginalText(this.content+" \n");
        }
        
        console.log("print success");
    }

    withEmptyLine(): PrintObject {
       this.emptyLine = true;
       return this;
      }
  

    withFullLine(): PrintObject {
        this.fullLine = true;
        return this;
    }

    withContent(content) {
        this.content = content;
        return this;
    }

    withBold() {
        this.bold = true;
        return this;
    }

    withNotBold() {
        this.bold = false;
        return this;
    }

    withNormal() {
        this.format = TextPrintObjectFormat.NORMAL;
        return this;
    }

    withM() {
        this.format = TextPrintObjectFormat.M;
        return this;
    }

    withL() {
        this.format = TextPrintObjectFormat.L;
        return this;
    }

    withXL() {
        this.format = TextPrintObjectFormat.XL;
        return this;
    }

    private getFontSize(){
        switch(this.format){
            case TextPrintObjectFormat.NORMAL: return this.NORMAL_SIZE;
            case TextPrintObjectFormat.M: return this.M_SIZE;
            case TextPrintObjectFormat.L: return this.L_SIZE;
            case TextPrintObjectFormat.XL: return this.XL_SIZE;
            case TextPrintObjectFormat.XXL: return this.XXL_SIZE;

        }
    }

}

export enum TextPrintObjectFormat {
    NORMAL,
    M,
    L,
    XL,
    XXL
}



