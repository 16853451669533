import { Component, Input, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { OrderCancelPossible } from 'src/app/interfaces/order/order-cancel-possible';
import { LoadingService } from 'src/app/services/helper/loading.service';
import { UtilityService } from 'src/app/services/helper/utility.service';
import { OrderService } from 'src/app/services/orders/order.service';

@Component({
  selector: 'app-edit-order-popover',
  templateUrl: './edit-order-popover.page.html',
  styleUrls: ['./edit-order-popover.page.scss'],
})
export class EditOrderPopoverPage implements OnInit {

  cancelPossible: OrderCancelPossible;
  currentOrderId = -1;

  @Input("state")
  state:string;

  constructor(
    private popoverController: PopoverController,
    private orderService: OrderService,
    private navParams: NavParams,
    private loadingService: LoadingService,
    private  utilityService:UtilityService,
  ) { }

  ngOnInit() {
    this.currentOrderId = this.navParams.get('orderId');
    /*if (this.currentOrderId !== -1 && this.currentOrderId !== undefined) {
      this.loadingService.startLoading('Stornierbarkeit überprüfen...');
      this.orderService.getOrderCancelPossible(this.currentOrderId).subscribe(
        (res) => {
          this.loadingService.stopLoading();
          this.cancelPossible = res;
        },
        (err) =>{
          this.loadingService.stopLoading();
        }
      );
    }*/
  }

  /**
   * user clicks on one item
   * @param pos position of item
   */
  onClickItem(pos: number) {
    this.popoverController.dismiss(pos);
  }

  isMobilePlatform(){
    return this.utilityService.isRunningOnMobilePlatform();
  }
}
