import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicStorageModule } from '@ionic/storage';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService } from './services/auth/auth-interceptor.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DatePipe } from '@angular/common';
import { OrderCancelModalPageModule } from './pages/modals/order-cancel-modal/order-cancel-modal.module';
import { GenericModalPageModule } from './pages/modals/generic-modal/generic-modal.module';
import { EditOrderPopoverPageModule } from './pages/popovers/edit-order-popover/edit-order-popover.module';
import { EditTimeModalPageModule } from './pages/modals/edit-time-modal/edit-time-modal.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AddModifierModalPageModule } from './pages/modals/add-modifier-modal/add-modifier-modal.module';
import { EditBasicinfoPageModule } from './pages/modals/edit-basicinfo/edit-basicinfo.module';
import { EditModifierSetPageModule } from './pages/modals/edit-modifier-set/edit-modifier-set.module';
import { AddRequiredModifierModalPageModule } from './pages/modals/add-required-modifier-modal/add-required-modifier-modal.module';
import { AddModifierSetToProductPageModule } from './pages/modals/add-modifier-set-to-product/add-modifier-set-to-product.module';
import { AddNewModifierSetToProductPageModule } from './pages/modals/add-new-modifier-set-to-product/add-new-modifier-set-to-product.module';
import { AddImagePageModule } from './pages/modals/add-image/add-image.module';
import { AddItemInstaceImagePageModule } from './pages/modals/add-item-instace-image/add-item-instace-image.module';
import { QRCodeModule } from 'angularx-qrcode';
import { Insomnia } from '@ionic-native/insomnia/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';






@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    IonicModule.forRoot(),
    AppRoutingModule,
    EditOrderPopoverPageModule,
    AddModifierModalPageModule,
    GenericModalPageModule,
    AddImagePageModule,
    AddItemInstaceImagePageModule,
    EditBasicinfoPageModule,
    EditModifierSetPageModule,
    OrderCancelModalPageModule,
    AddRequiredModifierModalPageModule,
    AddModifierSetToProductPageModule,
    EditTimeModalPageModule,
    ServiceWorkerModule.register(environment.combinedSwJs, { enabled: environment.production }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    FormsModule,
    ReactiveFormsModule,
    AddNewModifierSetToProductPageModule,
    QRCodeModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    DatePipe,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Insomnia,
    AppVersion,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
