import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { EditBasicinfoPageRoutingModule } from './edit-basicinfo-routing.module';

import { EditBasicinfoPage } from './edit-basicinfo.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    EditBasicinfoPageRoutingModule
  ],
  declarations: [EditBasicinfoPage]
})
export class EditBasicinfoPageModule {}
