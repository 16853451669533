import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { CategoryItem } from 'src/app/interfaces/item/category-item';
import { ItemInstance } from 'src/app/interfaces/item/item-instance';
import { ModifierSet } from 'src/app/interfaces/item/modifier-set';
import { ToastService } from 'src/app/services/helper/toast.service';
import { UtilityService } from 'src/app/services/helper/utility.service';
import { ProductService } from 'src/app/services/product-management/product.service';

@Component({
  selector: 'app-add-modifier-set-to-product',
  templateUrl: './add-modifier-set-to-product.page.html',
  styleUrls: ['./add-modifier-set-to-product.page.scss'],
})
export class AddModifierSetToProductPage implements OnInit {

  modifierSets: ModifierSet[] = [];
  currentProduct: CategoryItem;
  currentItemInstance:ItemInstance;

  constructor(
    private productService: ProductService,
    private modalController: ModalController,
    private toastService: ToastService,
    private navParams: NavParams,
    private utilityService: UtilityService,
  ) { }

  ngOnInit() {
    this.currentProduct = this.navParams.get('product');
    if(this.currentProduct==null){
      this.currentItemInstance = this.navParams.get('itemInstance');
    }
    
    this.productService.getPartnerModifierSet().subscribe(
      (res) => {
        this.modifierSets = res;
      },
      (err) => {
        this.toastService.showErrorToast('Laden hat nicht funktioniert.');
        this.modalController.dismiss();
      }
    );
  }

  /**
   * user clicks add modifier
   */
  onClickAddModifierSet(modifierSet: ModifierSet){
    if(this.currentProduct!=null){
      this.addModifierSetToProduct(modifierSet);
    }else{
      this.addModifierSetToItemInstance(modifierSet);
    }
  }

  addModifierSetToProduct(modifierSet: ModifierSet) {
    for (let i = 0; i < this.currentProduct.modifierSets.length; i++) {
      if (this.currentProduct.modifierSets[i].modifierSetId.modifierSetId === modifierSet.modifierSetId.modifierSetId) {
        this.toastService.showErrorToast('Auswahl wurde schon ausgewählt.');
        return;
      }
    }

    if(this.utilityService.addIfNotExists(modifierSet, this.currentProduct.modifierSets)) {
      this.utilityService.removeElementFromArray(modifierSet, this.modifierSets);
    } else {
      this.toastService.showErrorToast('Auswahl wurde schon ausgewählt.');
    }
  }

  addModifierSetToItemInstance(modifierSet: ModifierSet) {
    for (let i = 0; i < this.currentItemInstance.modifierSets.length; i++) {
      if (this.currentItemInstance.modifierSets[i].modifierSetId.modifierSetId === modifierSet.modifierSetId.modifierSetId) {
        this.toastService.showErrorToast('Auswahl wurde schon ausgewählt.');
        return;
      }
    }
    
    if(this.utilityService.addIfNotExists(modifierSet, this.currentItemInstance.modifierSets)) {
      this.utilityService.removeElementFromArray(modifierSet, this.modifierSets);
    } else {
      this.toastService.showErrorToast('Auswahl wurde schon ausgewählt.');
    }
  }

  /**
   * click to create new modifierSet
   */
  onClickCreateNewModifierSet() {
    this.modalController.dismiss(1);
  }

  onDismiss(){
    this.modalController.dismiss();
  }
}
