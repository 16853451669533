import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ImageDTO } from 'src/app/interfaces/settings/imagedto';
import { ItemInstanceImage } from 'src/app/interfaces/settings/item-instance-image';
import { S3Service } from 'src/app/services/aws/s3/s3.service';
import { LoadingService } from 'src/app/services/helper/loading.service';
import { ToastService } from 'src/app/services/helper/toast.service';
import { UtilityService } from 'src/app/services/helper/utility.service';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-item-instace-image',
  templateUrl: './add-item-instace-image.page.html',
  styleUrls: ['./add-item-instace-image.page.scss'],
})
export class AddItemInstaceImagePage implements OnInit {
  partnerFolder: string;
  bucket: string;

  imageToUpload: File;
  imageToUploadUrl: any;
  @ViewChild("uploader", { static: true }) uploader: ElementRef<HTMLInputElement>;

  //already available images
  images: ItemInstanceImage[];

  //-2 == no image selected, -1 = image is selected that needs to be uploaded, 0..n images that are already in database are selected
  selectedIndex: number = -2;
  selectedItemInstanceImage: ItemInstanceImage;
  isRunningOnMobilePlatform:boolean =false;

  constructor(private modalController: ModalController,
    private settingsService: SettingsService,
    private s3Service: S3Service,
    private utilityService: UtilityService,
    private loadingService: LoadingService,
    private toastService: ToastService) { 
      this.bucket = environment.s3.publicBucketName;
      this.isRunningOnMobilePlatform = utilityService.isRunningOnMobilePlatform();
    }

  ngOnInit() {
    this.getItemInstanceImages();
    this.getPartnerFolder();
  }

  popFileChooser(): void {
    if(!this.isRunningOnMobilePlatform){
      this.uploader.nativeElement.click();
    }else{
      this.utilityService.showFeatureNotSupportedModal();
    }
    
  }

  async handleFileInput(files: FileList) {
    console.log(files);
    this.imageToUpload = files.item(0);
    this.imageToUploadUrl = await this.utilityService.getDataUrl(this.imageToUpload);
    //the image that is added should be automatically be selected
    this.selectedIndex = -1;
  }


  getItemInstanceImages() {
    this.settingsService.getItemInstanceImages().subscribe(res => {
      this.images = res;
    });
  }

  /**
   * creates a new database entry for an itemInstance image
   */
  async addItemInstanceImage(image: ImageDTO) {
    return new Promise((resolve, reject) => {
      let data: ItemInstanceImage = {
        "image": image
      }
      console.log("saving item instance image to db")
      this.settingsService.postItemInstanceImage(data).subscribe(res => {
        console.log("successfully saved item instance image to db");
        this.selectedItemInstanceImage = res;
        resolve(null);
      });
    });
  }

  /**
   * callback when image is selected
   */
  onClickSelectItemInstanceImage(index: number) {
    console.log("select item instance image " + index);
    if (this.isChecked(index)) {
      console.log("deselect");
      this.selectedIndex = -2;
    } else {
      console.log("selectif");
      this.selectedIndex = index;
    }
  }

  /**
   *checks if itemInstance image is selected
   */
  isChecked(index) {
    return this.selectedIndex == index;
  }

  /**
   *checks there is somthing selected
   */
  isSomethingChecked() {
    return this.selectedIndex != -2 ? true : false;
  }

  /**
   * uploads image to s3 and triggers then a method so image url can be saved in db
   * @param imageToUpload 
   * @param uploadMethod to be triggered after image is uploaded to s3
   */
  async uploadImage(imageToUpload: File, uploadMethod: (image: ImageDTO) => (void)) {
    this.loadingService.startLoading("Lade Bild hoch");
    console.log("uploading image");
    let image: ImageDTO = {
      "bucket": this.bucket,
      "format": "png",
      "name": "img_" + this.utilityService.uuidv4(),
      "type": "image",
      "url": this.partnerFolder + "item/"
    };
    let key = image.url + image.name + "." + image.format;
    try {

      await this.s3Service.uploadFileToS3(image.bucket, key, imageToUpload);
      console.log("uploaded to s3");
      await uploadMethod(image);
    } catch (err) {
      console.log(err)
      this.toastService.showErrorToast("Fehler beim Hochladen des Bilds");
    } finally {
      this.loadingService.stopLoading();
    }
  }

  /**
   * if image is not uploaded to s3 and db yet, it is done, else the already available itemInstance image is selected
   */
  async onSave() {
    if (this.isChecked(-1)) {
      await this.uploadImage(this.imageToUpload, this.addItemInstanceImage.bind(this));
    } else {
      this.selectedItemInstanceImage = this.images[this.selectedIndex];
    }
    this.modalController.dismiss({
      "itemInstanceImage": this.selectedItemInstanceImage,
      "action": "SAVE"
    });
  }

  onDismiss() {
    this.modalController.dismiss({
    });
  }

  getPartnerFolder() {
    this.settingsService.getPartnerFolder().subscribe(res => {
      this.partnerFolder = res.folder;
    })
  }

}
