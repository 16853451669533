import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PageRequestData } from 'src/app/interfaces/filter-and-search/page-request-data';
import { PageResultData } from 'src/app/interfaces/filter-and-search/page-result-data';
import { Order } from 'src/app/interfaces/order/order';
import { OrderConfirmation } from 'src/app/interfaces/order/order-confirmation';
import { OrderCancelPossible } from 'src/app/interfaces/order/order-cancel-possible';
import { CancelData } from 'src/app/interfaces/order/cancel-data';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  SERVER_ADDRESS: string;

  /**
   * constructor
   */
  constructor(
    private httpClient: HttpClient
    ) {
      this.SERVER_ADDRESS = environment.serverAddress;
    }

  /**
   * Function to get open orders
   * @param user userdata
   */
  getOpenOrders(): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.SERVER_ADDRESS}/rest/partner/order/open`);
  }

  /**
   * Function to get order cancel possible
   * @param orderId id of order
   */
  getOrderCancelPossible(orderId: number): Observable<OrderCancelPossible> {
    return this.httpClient.get<OrderCancelPossible>(`${this.SERVER_ADDRESS}/rest/general/order/cancel/possible/` + orderId);
  }

  /**
   * Function to get order cancel possible
   * @param orderId id of order
   */
  getOrder(orderId: number): Observable<Order> {
    return this.httpClient.get<Order>(`${this.SERVER_ADDRESS}/rest/partner/order/` + orderId);
  }

  /**
   * Function to get orders by polling
   * @param orderId id of order
   */
  getOrdersByPolling(): Observable<Order[]> {
    return this.httpClient.get<Order[]>(`${this.SERVER_ADDRESS}/rest/partner/order/poll`);
  }

  /**
   * Function to get completed orders
   * @param user userdata
   */
  postOpenCompletedOrders(pageData: PageRequestData): Observable<PageResultData<Order>> {
    return this.httpClient.post<PageResultData<Order>>(`${this.SERVER_ADDRESS}/rest/partner/order/completed`, pageData);
  }

  /**
   * Function cancel an order
   * @param orderId id of order
   */
  postCancelOrder(orderId: number, cancelData: CancelData): Observable<Order> {
    return this.httpClient.post<Order>(`${this.SERVER_ADDRESS}/rest/partner/order/cancel/` + orderId, cancelData);
  }

  /**
   * Function confirm an order
   * @param orderConfirmation orderConfirmation object
   */
  postConfirmOrder(orderConfirmation: OrderConfirmation): Observable<Order> {
    return this.httpClient.post<Order>(`${this.SERVER_ADDRESS}/rest/partner/order/confirm`, orderConfirmation);
  }

  /**
   * Function set done order
   * @param orderId id of order to set to done
   */
  postDoneOrder(orderId: number): Observable<Order> {
    return this.httpClient.post<Order>(`${this.SERVER_ADDRESS}/rest/partner/order/done/` + orderId, orderId);
  }

  /**
   * Function cancel an order
   * @param orderId id of order
   */
  deleteCancelOrder(orderId: number): Observable<Order> {
    return this.httpClient.delete<Order>(`${this.SERVER_ADDRESS}/rest/partner/order/cancel/` + orderId);
  }
}
