import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PageRequestData } from 'src/app/interfaces/filter-and-search/page-request-data';
import { PageResultData } from 'src/app/interfaces/filter-and-search/page-result-data';
import { Additive } from 'src/app/interfaces/item/additive';
import { Allergene } from 'src/app/interfaces/item/allergene';
import { CreatePostRequest } from 'src/app/interfaces/settings/create-post-request';
import { DeliverySettings } from 'src/app/interfaces/settings/delivery-settings';
import { FeePlan } from 'src/app/interfaces/settings/fee-plan';
import { ImageDTO } from 'src/app/interfaces/settings/imagedto';
import { ItemInstanceImage } from 'src/app/interfaces/settings/item-instance-image';
import { NotificationEmail } from 'src/app/interfaces/settings/notification-email';
import { OpeningDay } from 'src/app/interfaces/settings/opening-day';
import { OpeningInfo } from 'src/app/interfaces/settings/opening-info';
import { OrderSettings } from 'src/app/interfaces/settings/order-settings';
import { PartnerBasicInfo } from 'src/app/interfaces/settings/partner-basic-info';
import { PartnerBasicStats } from 'src/app/interfaces/settings/partner-basic-stats';
import { PartnerCategory } from 'src/app/interfaces/settings/partner-category';
import { PartnerFeePlan } from 'src/app/interfaces/settings/partner-fee-plan';
import { PartnerPlansResponse } from 'src/app/interfaces/settings/partner-plan-response';
import { PaypalConnect } from 'src/app/interfaces/settings/paypal-connect';
import { Post } from 'src/app/interfaces/settings/post';
import { PrinterSettings } from 'src/app/interfaces/settings/printer-settings';
import { StartStatusResponse } from 'src/app/interfaces/settings/start-status-response';
import { StripeAccountInformationResponse } from 'src/app/interfaces/settings/stripe-account-information-response';
import { StripeBankAccountInfo } from 'src/app/interfaces/settings/stripe-bank-account-info';
import { StripeBankAccountRequest } from 'src/app/interfaces/settings/stripe-bank-account-request';
import { StripeOnboardingUrlRequest } from 'src/app/interfaces/settings/stripe-onboarding-url-request';
import { StripeOnboardingUrlResponse } from 'src/app/interfaces/settings/stripe-onboarding-url-response';
import { ZipCode } from 'src/app/interfaces/settings/zip-code';
import { ZipCodeSearch } from 'src/app/interfaces/settings/zip-code-search';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SettingsService {
 




  SERVER_ADDRESS: string;

  // reload start status
  reloadStartStatusSwitchFlag: BehaviorSubject<StartStatusResponse> = new BehaviorSubject(null);

  // reload start status
  orderCountSubject: BehaviorSubject<number> = new BehaviorSubject(0);

  /**
   * constructor
   */
  constructor(
    private httpClient: HttpClient,
  ) {
    this.SERVER_ADDRESS = environment.serverAddress;
  }


  /**
  * Function to get the basic infos of partner
  * @param user userdata
  */
  getPartnerBasicInfos(): Observable<PartnerBasicInfo> {
    return this.httpClient.get<PartnerBasicInfo>(`${this.SERVER_ADDRESS}/rest/partner/basicInfo`);
  }

  postBasicInfo(data: PartnerBasicInfo) {
    return this.httpClient.post<PartnerBasicInfo>(`${this.SERVER_ADDRESS}/rest/partner/basicInfo`, data);
  }

  getPartnerOpeningInfo(): Observable<OpeningInfo> {
    return this.httpClient.get<OpeningInfo>(`${this.SERVER_ADDRESS}/rest/partner/opening`);
  }

  postTogglePauseOrders(): Observable<void> {
    return this.httpClient.post<void>(`${this.SERVER_ADDRESS}/rest/partner/opening/pause`, null);
  }

  postPartnerOpeningRegular(openingDays: OpeningDay[]) {
    return this.httpClient.post<OpeningDay[]>(`${this.SERVER_ADDRESS}/rest/partner/opening/regular`, openingDays);
  }

  postLogo(data: ImageDTO) {
    return this.httpClient.post<ImageDTO>(`${this.SERVER_ADDRESS}/rest/partner/image/profile`, data);
  }

  deleteLogo() {
    return this.httpClient.delete<ImageDTO>(`${this.SERVER_ADDRESS}/rest/partner/image/profile`);
  }
  deleteTitleImage() {
    return this.httpClient.delete<ImageDTO>(`${this.SERVER_ADDRESS}/rest/partner/image/title/profile`);
  }

  getStartStatus(): Observable<StartStatusResponse> {
    return this.httpClient.get<StartStatusResponse>(`${this.SERVER_ADDRESS}/rest/partner/validation/start`);
  }

  getMessages(): Observable<UserMessage[]> {
    return this.httpClient.get<UserMessage[]>(`${this.SERVER_ADDRESS}/rest/general/messages`);
  }

  postActivatePartner(): Observable<any> {
    return this.httpClient.post<any>(`${this.SERVER_ADDRESS}/rest/partner/activate`, null);
  }

  getStripeAccount(): Observable<StripeAccountInformationResponse> {
    return this.httpClient.get<StripeAccountInformationResponse>(`${this.SERVER_ADDRESS}/rest/partner/stripe/account`);
  }

  getStripeOnboardingUrl(req: StripeOnboardingUrlRequest): Observable<StripeOnboardingUrlResponse> {
    return this.httpClient.post<StripeOnboardingUrlResponse>(`${this.SERVER_ADDRESS}/rest/partner/stripe/onboarding/url`, req);
  }

  updateBankAccount(req: StripeBankAccountRequest) {
    return this.httpClient.post<StripeBankAccountRequest>(`${this.SERVER_ADDRESS}/rest/partner/stripe/bank`, req);
  }

  getBankAccount(): Observable<StripeBankAccountInfo> {
    return this.httpClient.get<StripeBankAccountInfo>(`${this.SERVER_ADDRESS}/rest/partner/stripe/bank`);
  }

  getFeePlanById(id: number): Observable<FeePlan> {
    return this.httpClient.get<FeePlan>(`${this.SERVER_ADDRESS}/rest/partner/plan/` + id);
  }

  addStandardFeePlanToPartner(id: number) {
    return this.httpClient.post<FeePlan>(`${this.SERVER_ADDRESS}/rest/partner/fees/plan/` + id, null);
  }
  getPartnerFeePlans(): Observable<PartnerPlansResponse> {
    return this.httpClient.get<PartnerPlansResponse>(`${this.SERVER_ADDRESS}/rest/partner/fees/plans`);
  }

  getSelectablePartnerFeePlans(): Observable<FeePlan[]> {
    return this.httpClient.get<FeePlan[]>(`${this.SERVER_ADDRESS}/rest/partner/fees/plans/selectable`);
  }

  getAllPartnerCategories(): Observable<PartnerCategory[]> {
    return this.httpClient.get<PartnerCategory[]>(`${this.SERVER_ADDRESS}/rest/partner/category/partner`);
  }

  getPartnerCategoriesByBusinessCategory(businessCategory: string) {
    return this.httpClient.get<PartnerCategory[]>(`${this.SERVER_ADDRESS}/rest/partner/category/partner/` + businessCategory);
  }

  resendVerificationEmail(): Observable<string> {
    return this.httpClient.post<string>(`${this.SERVER_ADDRESS}/rest/partner/info/resend`, null);
  }

  postCreatePost(data: CreatePostRequest): Observable<Post> {
    return this.httpClient.post<Post>(`${this.SERVER_ADDRESS}/rest/partner/post`, data);
  }

  getPosts(page: PageRequestData): Observable<PageResultData<Post>> {
    return this.httpClient.post<PageResultData<Post>>(`${this.SERVER_ADDRESS}/rest/partner/posts`, page);
  }

  deletePost(index: number) {
    console.log("delete post api " + index);
    return this.httpClient.delete<Post[]>(`${this.SERVER_ADDRESS}/rest/partner/post/` + index);
  }

  getItemInstanceImages(): Observable<ItemInstanceImage[]> {
    return this.httpClient.get<ItemInstanceImage[]>(`${this.SERVER_ADDRESS}/rest/partner/itemInstanceImages`);
  }

  postItemInstanceImage(image: ItemInstanceImage): Observable<ItemInstanceImage> {
    return this.httpClient.post<ItemInstanceImage>(`${this.SERVER_ADDRESS}/rest/partner/itemInstanceImage`, image);
  }
  getPartnerFolder(): Observable<PartnerFolder> {
    return this.httpClient.get<PartnerFolder>(`${this.SERVER_ADDRESS}/rest/partner/folder`);
  }

  getPartnerBasicStats(): Observable<PartnerBasicStats> {
    return this.httpClient.get<PartnerBasicStats>(`${this.SERVER_ADDRESS}/rest/partner/stats/basic`);
  }

  getPaypalInfo(): Observable<PaypalConnect> {
    return this.httpClient.get<PaypalConnect>(`${this.SERVER_ADDRESS}/rest/partner/paypal/connect`);
  }

  connectPayPal(data: PaypalConnect) {
    return this.httpClient.post<PaypalConnect>(`${this.SERVER_ADDRESS}/rest/partner/paypal/connect`, data);
  }

  deletePayPalConnection() {
    return this.httpClient.delete<void>(`${this.SERVER_ADDRESS}/rest/partner/paypal/connect`);
  }

  updateDeliverySettings(data: DeliverySettings) {
    return this.httpClient.post<DeliverySettings>(`${this.SERVER_ADDRESS}/rest/partner/settings/delivery`, data);
  }
  updateOrderSettings(data: OrderSettings) {
    return this.httpClient.post<DeliverySettings>(`${this.SERVER_ADDRESS}/rest/partner/settings/order`, data);
  }
  updatePrinterSettings(data: any) {
    return this.httpClient.post<PrinterSettings>(`${this.SERVER_ADDRESS}/rest/partner/settings/printer`, data);
  }
  togglePrinterActivation() {
    return this.httpClient.post<string>(`${this.SERVER_ADDRESS}/rest/partner/settings/printer/activation`, null);
  }
  toggleDeliveryActivation() {
    return this.httpClient.post<string>(`${this.SERVER_ADDRESS}/rest/partner/settings/delivery/activation`, null);
  }
  getOrderSettings(): Observable<OrderSettings> {
    return this.httpClient.get<OrderSettings>(`${this.SERVER_ADDRESS}/rest/partner/settings/order`);
  }
  getDeliverySettings(): Observable<DeliverySettings> {
    return this.httpClient.get<DeliverySettings>(`${this.SERVER_ADDRESS}/rest/partner/settings/delivery`);
  }
  getPrinterSettings() {
    return this.httpClient.get<PrinterSettings>(`${this.SERVER_ADDRESS}/rest/partner/settings/printer`);
  }
  togglePauseDelivery() {
    return this.httpClient.post<string>(`${this.SERVER_ADDRESS}/rest/partner/settings/delivery/pause`, null);
  }
  searchZipCodes(zipCodeSearch: ZipCodeSearch): Observable<ZipCode[]> {
    return this.httpClient.post<ZipCode[]>(`${this.SERVER_ADDRESS}/rest/partner/settings/delivery/zipCode`, zipCodeSearch);
  }

  getNotificationEmails(): Observable<NotificationEmail[]> {
    return this.httpClient.get<NotificationEmail[]>(`${this.SERVER_ADDRESS}/rest/partner/settings/notifications`);
  }

  addNotificationEmail(email:NotificationEmail) {
    return this.httpClient.post<NotificationEmail[]>(`${this.SERVER_ADDRESS}/rest/partner/settings/notifications`, email);
  }
  
  deleteNotificationEmail(id:number) {
    return this.httpClient.delete<NotificationEmail[]>(`${this.SERVER_ADDRESS}/rest/partner/settings/notifications/`+id);
  }

  togglePauseNotificationEmail(id:number) {
    return this.httpClient.post<NotificationEmail[]>(`${this.SERVER_ADDRESS}/rest/partner/settings/notifications/pause/`+id, null);
  }

  sendVerificationEmail(id:number) {
    return this.httpClient.post<NotificationEmail[]>(`${this.SERVER_ADDRESS}/rest/partner/settings/notifications/verify/`+id, null);
  }

  getAllAllergenes() {
    return this.httpClient.get<Allergene[]>(`${this.SERVER_ADDRESS}/rest/general/allergenes`);
  }

  getAllAdditives() {
    return this.httpClient.get<Additive[]>(`${this.SERVER_ADDRESS}/rest/general/additives`);
  }


 


  /**
   * set new start status reload
   */
  setReloadStartStatusSwitchFlag(status: StartStatusResponse) {
    this.reloadStartStatusSwitchFlag.next(status);
  }

  /**
  * set new start status reload
  */
  setNewOrderCount(orderCount: number) {
    this.orderCountSubject.next(orderCount);
  }
}
