import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Modifier } from 'src/app/interfaces/item/modifier';
import { UtilityService } from 'src/app/services/helper/utility.service';

@Component({
  selector: 'app-add-modifier-modal',
  templateUrl: './add-modifier-modal.page.html',
  styleUrls: ['./add-modifier-modal.page.scss'],
})
export class AddModifierModalPage implements OnInit {

  modifier: Modifier = {
    id: 0,
    price: '',
    modifierItem: {
      modifierItemId: {
        modifierItemId: 0
      },
      name: '',
      additives: [],
      allergenes: [],
      availability: 'AVAILABLE'
    },
    selected: false,
  };
  emptyData = false;

  constructor(
    private modalController: ModalController,
    private utilityService: UtilityService
  ) { }

  ngOnInit() {
  }

  /**
   * user clicks add modifier
   */
  onClickAddItem() {
    if (this.modifier.modifierItem.name === '') {
      this.emptyData = true;
      return;
    }
    if (this.modifier.price === '') {
      this.modifier.price = '0.00';
    }

    this.modifier.price = this.utilityService.parseStringToPriceFormat(this.modifier.price);
    
    this.emptyData = false;
    this.modalController.dismiss(this.modifier);
  }
}
