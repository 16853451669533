import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { map, catchError, switchMap, retryWhen, finalize, tap, delay, } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { AuthService } from './auth.service';
import { ToastService } from '../helper/toast.service';
import { Router } from '@angular/router';

export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  private DEBUG = true;
  private MAX_RETRIES = 0;

  constructor(
    private storage: Storage,
    private authService: AuthService,
    private toastService: ToastService,
    private router: Router,
    ) { }

  /**
   * intercept every http request and append token
   * @param request http request
   * @param next httpHandler
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    //skip interceptor if InterceptorSkipHeader is set
    if (request.headers.has(InterceptorSkipHeader)) {
      const headers = request.headers.delete(InterceptorSkipHeader);
      return next.handle(request.clone({ headers }));
    }

    return from(this.storage.get('ID_TOKEN')).pipe(
      switchMap(
        token => {
          // if token available then set in bearer
          if (token) {
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${token}`
              }
            });
          }

          // set headers
          if (!request.headers.has('Content-Type')) {
            request = request.clone({
              setHeaders: {
                'content-type': 'application/json',
              }
            });
            request = request.clone({
              headers: request.headers.set('Accept', 'application/json, text/plain, */*')
            });
          }

          if (this.DEBUG) {
            console.log(request);
          }

          return next.handle(request).pipe(
            // catch TOKEN_EXPIRED
            catchError(
              (error) => {
                if (error instanceof HttpErrorResponse) {
                  if (this.DEBUG) {
                    console.log(error);
                  }
                  if (error.error !== null) {
                    //TODO: also add case with "Unauthorized", but pay attention to endless loops
                    if (error.error.message?.includes('TOKEN_EXPIRED')) {
                      return this.handleTokenExpired(request, next);
                    } else {
                      return throwError(error);
                    }
                  } else {
                    return throwError(error);
                  }
                } else {
                  return throwError(error);
                }
              }
            ),
            // // retries
            // retryWhen(
            //   (err) => {
            //     let retries = 1;
            //     return err.pipe(
            //       delay(3000),
            //       tap(
            //         () => {
            //           this.toastService.showErrorToast('Anfrage wird wiederholt ' + retries + '/' + this.MAX_RETRIES);
            //         }
            //       ),
            //       map(
            //         (error) => {
            //           if (retries++ >= this.MAX_RETRIES) {
            //             throw error;
            //           }
            //           return error;
            //         }
            //       )
            //     );
            //   }
            // ),
            // handle request
            map(
              (event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                  if (this.DEBUG) {
                    console.log(event);
                  }
                  return event;
                }
            }),
            // general error
            catchError(
              (error) => {
                if (this.DEBUG) {
                  console.log(error);
                }
                return throwError(error);
              }
            ),
          );
        }
      )
    );
  }

  /**
   * handle TOKEN_EXPIRED errors by refreshing token
   */
  handleTokenExpired(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('refreshing Token');
    return from(this.authService.refreshSession()).pipe(
      switchMap(
        (token) => {
          // if token available then set in bearer
          if (token) {
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${token}`
              }
            });
          }

          // set headers
          if (!request.headers.has('Content-Type')) {
            request = request.clone({
              setHeaders: {
                'content-type': 'application/json',
              }
            });
            request = request.clone({
              headers: request.headers.set('Accept', 'application/json, text/plain, */*')
            });
          }

          if (this.DEBUG) {
            console.log(request);
          }

          return next.handle(request).pipe(
            // handle request
            map(
              (event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                  if (this.DEBUG) {
                    console.log(event);
                  }
                  return event;
                }
            }),
            // general error
            catchError(
              (error) => {
                if (this.DEBUG) {
                  console.log(error);
                }
                return throwError(error);
              }
            ),
          );
        }
      ),
      // retry failed
      catchError(
        (error) => {
          if (this.DEBUG) {
            console.log(error);
          }
          this.authService.loggedIn.next(false);
          this.router.navigate(['login']);
          return throwError(error);
        }
      ),
    );
  }
}
