import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { throwError } from 'rxjs';

import { AuthService } from '../../auth/auth.service';
import { S3Config } from 'src/app/interfaces/config/s3-config';
import { CognitoConfig } from 'src/app/interfaces/config/cognito-config';
import { S3Control } from 'aws-sdk/clients/all';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class S3Service {

  s3Config: S3Config;
  cognitoConfig: CognitoConfig;


  constructor(private authService: AuthService,) {
    this.s3Config = environment.s3;
    this.cognitoConfig = environment.cognito;
  }

  private async client():Promise<S3>{
    await this.updateCredentials();
    let s3:S3 = new S3({
      apiVersion: "2006-03-01"
    });
    return s3;
  }

  private async updateCredentials() {
    //TODO: here evtl if not loggedIn refresh token and return new token
    let token = await this.authService.isLoggedIn();
    let key = 'cognito-idp.' + this.s3Config.bucketRegion + '.amazonaws.com/' + this.cognitoConfig.userPoolId;
    // config for s3
    AWS.config.update({
      region: this.s3Config.bucketRegion,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: this.cognitoConfig.identityPoolId,
        Logins: {
          [key]: token
        }
      })
    });
  }

  async uploadFileToS3(bucket: string, key: string, file: File): Promise<S3.ManagedUpload.SendData> {
    let client:S3 = await this.client();
    let dataUrl = await this.getDataUrl(file);
    let blob = this.dataUrlToBlob(dataUrl, file.type);
    // Refresh and Upload
    //let err: any = await this.getAWSCredentials();
    // if (err) {
    //   throwError(err); // credentials not loaded
    // }
    let params: S3.Types.PutObjectRequest = {
      Key: key,
      Body: blob,
      Bucket: bucket
    };
    console.log("uploading to s3");
    return client.upload(params).promise()
  }


  /***************************************************
   * HELPER (TODO auslagern)
   *************************************************/

  getDataUrl = (file: File) => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.readAsDataURL(file)
    })
  }

  // https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
  dataUrlToBlob(dataUrl, contentType) {
    // remove data:image/png;base64, from
    // data:image/png;base64,iVBORw0KGg....
    let tmp = dataUrl.split(',');

    const byteCharacters = atob(tmp[1]);

    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    const blob = new Blob([byteArray], { type: contentType });

    return blob;
  }

  getAWSCredentials() {
    return new Promise((resolve, reject) => {
      AWS.config.getCredentials(function (err) {
        resolve(err);
      });
    });
  }
}
