import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-edit-time-modal',
  templateUrl: './edit-time-modal.page.html',
  styleUrls: ['./edit-time-modal.page.scss'],
})
export class EditTimeModalPage implements OnInit {

  extraTime = [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];
  // this is cooler lol
  // extraTime = Array.from({length: 11}, (_, x) => 10 + x * 5);
  currentPUT: string;
  newPUT: string;
  selected = false;

  constructor(
    private modalController: ModalController,
    private navParams: NavParams
  ) { }

  ngOnInit() {
    this.currentPUT = this.navParams.get('timeProposal');
  }

  /**
   * user clicks close
   */
  onClickClose() {
    this.modalController.dismiss();
  }
  
  /**
   * user clicks edit put
   */
  onClickEdit() {
    this.modalController.dismiss(this.newPUT);
  }

  /**
   * user selects extra time
   */
  onChangeSelect(event) {
    const extraTimeSelectedInMinutes: number = event.detail.value;
    const newPUTDate = new Date(this.currentPUT);
    newPUTDate.setMinutes(newPUTDate.getMinutes() + extraTimeSelectedInMinutes);
    this.newPUT = newPUTDate.toISOString();
    this.selected = true;
  }
}
