import { OrderDiscount } from "../../order-discount";
import { PrintObject } from "../print-object";


export abstract class OrderPrint {
    
    public abstract printOrder(printData: PrintObject[]);
    
    constructor() {
    }

    deliveryAddress: any;
    transportType: string;
    orderCancel: {
        cancelReason: string;
        canceledBy: {
            username: string;
            role: string;
        };
        message: string;
        timestamp: string;
    };
    date: string;
    finalStateDate: string;

    orderDiscount: OrderDiscount;

    orderer: {
        userId: number;
        username: string;
        surname: string;
        lastname:string;
        mobilePhoneNumber:string;
    };
    orderId: number;
  
    orderStateHistory: {
        createdDate: string;
        state: string;
    }[];
    partner: {
        partnerUserId: number;
        officialName: string;
        address: {
            addressLine1: string;
            addressLine2: string;
            city: string;
            zipCode: string;
        },
        profilePicture: {
            s3Url: string;
        },
    };
    timeAcceptance: string;
    timeProposal: string;
    pickuper: {
        username: string;
        surname: string;
        lastname:string;
        fullName:string;
        mobilePhoneNumber:string;
    };
    show: boolean;
    state: string;
    reason: string;
  
    type: string;

    totalBasePrice: number;
    totalPrice: number;
    deliveryFee: number;

}
