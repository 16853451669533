import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Modifier } from 'src/app/interfaces/item/modifier';
import { ModifierSet } from 'src/app/interfaces/item/modifier-set';
import { UtilityService } from 'src/app/services/helper/utility.service';

@Component({
  selector: 'app-edit-modifier-set',
  templateUrl: './edit-modifier-set.page.html',
  styleUrls: ['./edit-modifier-set.page.scss'],
})
export class EditModifierSetPage implements OnInit {

  modifierSet: ModifierSet;
  newModifier: Modifier = {
    id: 0,
    price: '',
    modifierItem: {
      modifierItemId: {
        modifierItemId: 0
      },
      name: '',
      additives: [],
      allergenes: [],
      availability: 'AVAILABLE'
    },
    selected: false,
  };
  showNewItem = true;
  emptyNewModifier = false;
  isMobileLayout = false;

  constructor(
    private navParams: NavParams,
    private utilityService: UtilityService,
    private modalController: ModalController,
  ) { }

  ngOnInit() {
    this.modifierSet = this.utilityService.copy(this.navParams.get('modifierSet'));
    this.isMobileLayout = window.innerWidth <= 700;
  }

  /**
   * user adds modifier item
   */
  onClickAddModifierItem() {
    if (this.showNewItem) {
      if (this.newModifier.modifierItem.name === '') {
        this.emptyNewModifier = true;
        return;
      } else {
        this.emptyNewModifier = false;
      }
      if (this.newModifier.price === '') {
        this.newModifier.price = '0.00';
      }
      this.addNewItemToModifierSet();
    }
    this.showNewItem = true;
  }

  /**
   * user wants to delete modifier
   * 
   * @param modifier modifier to delete
   */
   onClickDelete(modifier: Modifier) {
    this.utilityService.removeElementFromArray(modifier, this.modifierSet.modifiers);
   }

  /**
   * adds new item to modifier set
   */
  private addNewItemToModifierSet() {
    if (this.modifierSet !== undefined && this.modifierSet.modifiers !== undefined) {
      const mod = this.utilityService.copy(this.newModifier);
      mod.price = this.utilityService.parseStringToPriceFormat(mod.price);

      this.newModifier.price = '';
      this.newModifier.modifierItem.name = '';
      this.modifierSet.modifiers.push(mod);
    }
  }

  /**
   * user clicks confirm
   */
  onClickConfirm() {
    if (this.newModifier.modifierItem.name !== '') {
      if (this.newModifier.price === '') {
        this.newModifier.price = '0.00';
      }
      this.addNewItemToModifierSet();
    }
    this.modalController.dismiss(this.modifierSet);
  }

  onDismiss(){
    this.modalController.dismiss(null);
  }
}
